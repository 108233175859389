import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DateFormats } from '@hoot/constants/constants';
import { SchoolReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetSchoolReportingMetrics';
import useGetStudentReportingMetrics, {
  StudentReportingMetricSortKey,
  StudentReportingRequest,
} from '@hoot/hooks/api/district-rep/reporting/useGetStudentReportingMetrics';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE } from '@hoot/models/api/pagination';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';

const defaultQuery: StudentReportingRequest = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  sortBy: StudentReportingMetricSortKey.StudentName,
  orderBy: OrderBy.Asc,
};

interface TableRow {
  studentName: React.ReactNode;
  studentNumber: number;
  averageAttendanceRate: string;
  lateJoinRate: string;
  minutesInLesson: string;
  firstLesson: string;
  mostRecentLesson: string;
}

const headers: HeaderData<TableRow>[] = [
  { name: 'Student Name', property: 'studentName', isSortable: true },
  { name: 'Student Number', property: 'studentNumber', isSortable: true },
  { name: 'Attendance', property: 'averageAttendanceRate', isSortable: true },
  { name: 'Late Join', property: 'lateJoinRate', isSortable: true },
  { name: 'Minutes in Lesson', property: 'minutesInLesson', isSortable: true },
  { name: 'First Lesson', property: 'firstLesson', isSortable: true },
  { name: 'Most Recent Lesson', property: 'mostRecentLesson', isSortable: true },
];

interface StudentMetricsTableProps {
  schoolId: string;
  filters: SchoolReportingFilters;
}

const StudentMetricsTable = (props: StudentMetricsTableProps) => {
  const { schoolId, filters } = props;

  const [paginationQuery, setPaginationQuery] = useState<StudentReportingRequest>(defaultQuery);
  const [tableResults, setTableResults] = useState<TableRow[]>([]);

  const { data } = useGetStudentReportingMetrics(schoolId, paginationQuery, filters, {
    onSuccess: (res) => {
      const formattedTableData = res.data.map(
        (x): TableRow => ({
          studentName: x.studentName,
          studentNumber: x.studentNumber,
          averageAttendanceRate: `${x.averageAttendanceRate}%`,
          lateJoinRate: `${x.lateJoinRate}%`,
          minutesInLesson: x.minutesInLesson.toLocaleString(),
          firstLesson: DateTime.fromISO(x.firstLesson).toFormat(DateFormats.SHORT_MDY),
          mostRecentLesson: DateTime.fromISO(x.mostRecentLesson).toFormat(DateFormats.SHORT_MDY),
        }),
      );

      setTableResults(formattedTableData);
    },
  });

  // Side effect for resetting the Page back to 1 when filters are applied.
  useEffect(() => {
    setPaginationQuery((prev) => ({ ...prev, page: 1 }));
  }, [filters]);

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HootTypography variant="titlelarge" mb={1} isPII={false}>
          Students
        </HootTypography>

        {/* TODO: SC-12357 */}
        {/* <Button variant="contained" startIcon={<ArrowIcon rotate={RotateDirection.Down} htmlColor="#FFF" />}>Download CSV</Button> */}
      </Stack>
      <TableV2
        data={tableResults ?? []}
        headers={headers}
        count={data?.count ?? 0}
        page={data?.page ? data.page - 1 : 0}
        allowRowsPerPage
        isPaginated
        isSortable
        sortOrder={paginationQuery.orderBy}
        sortBy={paginationQuery.sortBy}
        rowsPerPage={paginationQuery.pageSize}
        rowsPerPageOptions={[10, 25, 100]}
        onSortBy={(sortKey) => {
          setPaginationQuery((prev) => ({
            ...prev,
            page: 1,
            sortBy: sortKey,
            orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
          }));
        }}
        onPageChange={(_e, page) => {
          setPaginationQuery((prev) => ({ ...prev, page: page + 1 }));
        }}
        onRowsPerPageChange={(e) => {
          setPaginationQuery((prev) => ({ ...prev, page: 1, pageSize: +e.target.value }));
        }}
      />
    </div>
  );
};

export default StudentMetricsTable;
