import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { InstructionalUnitResponse } from '@hoot/hooks/api/library/useGetInstructionalUnitsForInstructionalFocus';
import useGetPrioritizedSkillsAndResourcesForInstructionalUnit, {
  PrioritizedSkillAndResourcesResponse,
} from '@hoot/hooks/api/library/useGetPrioritizedSkillsAndResourcesForInstructionalUnit';
import { BookType, DecodableFormat } from '@hoot/models/api/library';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { FocusAreaSlug, ScopeAndSequenceFocusArea } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';

interface TableRow {
  prioritizedSkillId: string;
  prioritizedSkillName: string;
  resources?: ReactNode;
  wiseWordsResources?: ReactNode;
  sampleWordsAndTasks?: ReactNode;
  pathway?: ReactNode;
}

const getHeaders = (focusAreaSlug: FocusAreaSlug, isMobile: boolean): HeaderData<TableRow>[] => {
  switch (focusAreaSlug) {
    case FocusAreaSlug.preWordReading:
      return [
        { name: 'Prioritized Skill ID', property: 'prioritizedSkillId', isHidden: true },
        { name: 'Prioritized Skill', property: 'prioritizedSkillName', width: isMobile ? '33%' : '25%' },
        { name: 'Resources', property: 'resources', width: isMobile ? '34%' : '40%' },
        { name: 'Sample Tasks', property: 'sampleWordsAndTasks', width: isMobile ? '33%' : '35%' },
      ];
    case FocusAreaSlug.earlyWordReading:
    case FocusAreaSlug.complexWordReading:
      return [
        { name: 'Prioritized Skill ID', property: 'prioritizedSkillId', isHidden: true },
        { name: 'Prioritized Skill', property: 'prioritizedSkillName', width: '25%' },
        { name: 'Wise Words', property: 'wiseWordsResources', width: '25%' },
        // Essentially just all resources that are _not_ wise words format.
        { name: 'Resources', property: 'resources', width: '25%' },
        { name: 'Sample Words', property: 'sampleWordsAndTasks', width: '25%' },
      ];
    case FocusAreaSlug.textReading:
      return [
        { name: 'Prioritized Skill ID', property: 'prioritizedSkillId', isHidden: true },
        { name: 'Prioritized Skill', property: 'prioritizedSkillName', width: isMobile ? '50%' : '60%' },
        { name: 'Pathway', property: 'pathway', width: isMobile ? '50%' : '40%' },
      ];
    default:
      console.error(`Can't map table data. Invalid focus area slug ${focusAreaSlug}`);
      return [];
  }
};

const mapTableData = (focusAreaSlug: FocusAreaSlug, results: PrioritizedSkillAndResourcesResponse[]): TableRow[] => {
  switch (focusAreaSlug) {
    case FocusAreaSlug.preWordReading:
      return results.map((skill) => ({
        prioritizedSkillId: skill.prioritizedSkillId,
        prioritizedSkillName: skill.prioritizedSkillName,
        resources: (
          <Stack>
            {skill.books.map((book) => (
              <Link to={routesDictionary.library.book.url(book.id)}>
                <HootTypography key={book.id} isPII={false} variant="tablevalue">
                  {book.title}
                </HootTypography>
              </Link>
            ))}
          </Stack>
        ),
        sampleWordsAndTasks: (
          <Stack>
            {skill.sampleTasksAndWords.map((x) => (
              <HootTypography key={x} isPII={false} variant="tablevalue">
                {x}
              </HootTypography>
            ))}
          </Stack>
        ),
      }));
    case FocusAreaSlug.earlyWordReading:
    case FocusAreaSlug.complexWordReading:
      return results.map((skill) => ({
        prioritizedSkillId: skill.prioritizedSkillId,
        prioritizedSkillName: skill.prioritizedSkillName,
        wiseWordsResources: (
          <Stack>
            {skill.books
              .filter((b) => b.decodableFormatIdEnum === DecodableFormat.WiseWords)
              .map((book) => (
                <Link to={routesDictionary.library.book.url(book.id)}>
                  <HootTypography key={book.id} isPII={false} variant="tablevalue">
                    {book.title}
                  </HootTypography>
                </Link>
              ))}
          </Stack>
        ),
        resources: (
          <Stack>
            {skill.books
              .filter((b) => b.decodableFormatIdEnum !== DecodableFormat.WiseWords)
              .map((book) => (
                <Link to={routesDictionary.library.book.url(book.id)}>
                  <HootTypography key={book.id} isPII={false} variant="tablevalue">
                    {book.title}
                  </HootTypography>
                </Link>
              ))}
          </Stack>
        ),
        sampleWordsAndTasks: (
          <HootTypography isPII={false} variant="tablevalue">
            {skill.sampleTasksAndWords.join(', ')}
          </HootTypography>
        ),
      }));
    case FocusAreaSlug.textReading:
      return results.map((skill) => ({
        prioritizedSkillId: skill.prioritizedSkillId,
        prioritizedSkillName: skill.prioritizedSkillName,
        pathway: (
          // TODO J 13169: Add link to skill documentation -> https://app.shortcut.com/hoot-reading/story/13150/path-create-unit-details-page
          <Link to="#">
            <Button variant="contained" color={'neutral.190'} size="small">
              View Documentation
            </Button>
          </Link>
        ),
      }));
    default:
      console.error(`Can't map table data. Invalid focus area slug ${focusAreaSlug}`);
      return [];
  }
};

interface InstructionalUnitDetailsCardProps {
  focusArea: ScopeAndSequenceFocusArea;
  instructionalUnit: InstructionalUnitResponse;
}

const InstructionalUnitDetailsCard = (props: InstructionalUnitDetailsCardProps) => {
  const { instructionalUnit, focusArea } = props;

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [tableRows, setTableRows] = useState<TableRow[]>([]);

  const getInstructionalUnitDetailsRequest = useGetPrioritizedSkillsAndResourcesForInstructionalUnit(
    instructionalUnit.id,
    {
      ...(focusArea.slug === FocusAreaSlug.textReading && {
        omitSampleWordsAndTasks: true,
        omitResources: true,
      }),
      excludedResourceTypes: [BookType.HootReadingAssessment, BookType.ProgressMonitoringAssessment],
      resourceType: BookType.Instruction,
    },
    {
      onSuccess: (results) => {
        setTableRows(mapTableData(focusArea.slug, results));
      },
    },
  );

  return (
    <Card isLoading={getInstructionalUnitDetailsRequest.isFetching}>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <HootTypography isPII={false} variant="titlemedium">
            {instructionalUnit.name} - Unit Details
          </HootTypography>
          {/* TODO J 13169: Add link to unit documenation -> https://app.shortcut.com/hoot-reading/story/13150/path-create-unit-details-page */}
          {focusArea.slug !== FocusAreaSlug.textReading && (
            <Link to="#">
              <Button variant="contained">View Documentation</Button>
            </Link>
          )}
        </Stack>
        <TableV2 headers={getHeaders(focusArea.slug, isSmallDevice)} data={tableRows} />
      </Stack>
    </Card>
  );
};

const InstructionalUnitDetailsCardWrapper = () => {
  const { instructionalUnit, focusArea } = useOutletContext<{
    instructionalUnit: InstructionalUnitResponse;
    focusArea: ScopeAndSequenceFocusArea;
  }>();

  return instructionalUnit ? <InstructionalUnitDetailsCard instructionalUnit={instructionalUnit} focusArea={focusArea} /> : null;
};

export default InstructionalUnitDetailsCardWrapper;
