import { AssessmentReportStatus } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/assessment-reporting';
import { HootAssessmentModule } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/core';
import Chart, { ChartWrapperOptions } from 'react-google-charts';
import { InstructionalFocusUnit, useGetInstructionalFocusReport } from '@hoot/hooks/api/assessment/useGetStudentInstructionalFocusAreasReport';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { statusColorMap } from '../assessments/instructional-focus-areas/StudentInProgressModules';
import StudentTextReadingTable from './StudentTextReadingTable';

interface UnitDialogProps {
  studentProfileId: string;
  unitName: string | null;
  onDismiss: VoidFunction;
}

const UnitDialog = (props: UnitDialogProps) => {
  const { data: focusData } = useGetInstructionalFocusReport(props.studentProfileId);

  const getModuleAndUnit = (): { unit: InstructionalFocusUnit; module: HootAssessmentModule } | undefined => {
    if (!focusData) return;

    for (const module of focusData) {
      const unitFound = module.units.find((unit) => unit.label === props.unitName);
      if (unitFound) {
        return { unit: unitFound, module: module.module };
      }
    }
    return undefined;
  };

  const { unit, module } = getModuleAndUnit() ?? {};

  const chartData = !!unit?.submissions?.length
    ? [
        ['Date', 'Score', { role: 'style' }],
        ...unit.submissions.map((submission) => {
          const score = submission.score || 0;
          const statusColour = statusColorMap[submission.status];
          return [submission.date, score, statusColour];
        }),
      ]
    : [];

  const options: ChartWrapperOptions['options'] = {
    legend: 'none',
    bar: { groupWidth: 40 },
    vAxis: {
      title: 'Score',
      viewWindow: {
        min: 0,
        max: unit?.maxScore ?? 5,
      },
    },
    hAxis: {
      title: 'Date',
    },
  };

  const handleClose = () => props.onDismiss();

  const isMastered = unit && [AssessmentReportStatus.Mastered, AssessmentReportStatus.Bypassed].includes(unit.status);
  const unitNotStartedOrMastered = unit && module && !(isMastered || [AssessmentReportStatus.NotStarted].includes(unit.status));
  // Render Chart if the unit belongs to Modules 1-3 and has not been Mastered, Bypassed or Started.
  const renderChart = unitNotStartedOrMastered && [HootAssessmentModule.One, HootAssessmentModule.Two, HootAssessmentModule.Three].includes(module);
  // Render Table if the unit belongs to Module 4, or if the unit is `Reading Fluency` (which is 2 units combined, Phrasing & Expression and Accuracy & Pace).
  const renderTable = module === HootAssessmentModule.Four || props.unitName === 'Reading Fluency';

  return (
    <BasicAlertDialog
      title={props.unitName ?? ''}
      show={!!props.unitName}
      onDismiss={handleClose}
      maxWidth={renderTable ? 'md' : 'xs'}
      content={
        <div>
          <HootTypography variant="titlesmall" textAlign="center" isPII={false}>
            {`${props.unitName} History`}
          </HootTypography>

          {renderTable ? (
            <StudentTextReadingTable unitName={props.unitName} />
          ) : renderChart ? (
            <Chart chartType="ColumnChart" data={chartData} options={options} style={{ padding: 8 }} />
          ) : (
            <ViewStateIllustration
              illustration={isMastered ? IllustrationEnum.SetupComplete : IllustrationEnum.EmptyState}
              title={''}
              subtitle={isMastered ? 'This unit has been mastered' : 'This unit has not been assessed'}
              padding={0}
            />
          )}
        </div>
      }
      primaryAction={{
        label: 'Close',
        onClick: handleClose,
        props: {
          variant: 'contained',
        },
      }}
    />
  );
};

export default UnitDialog;
