import { useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateFormats } from '@hoot/constants/constants';
import {
  GetStudentTextReadingsQuery,
  HootTextReadingResponse,
  StudentTextReadingsQuerySortKeyEnum,
  useGetTextReadings,
} from '@hoot/hooks/api/assessment/useGetStudentTextReadings';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';

interface TextReadingTableRow {
  assessmentId: string;
  startDate: string;
  bookTitle: string;
  bookLevel: string;
  fluency: string;
  vocabulary: string;
  textSpecificKnowledge: string;
  verbalReasoning: string;
  textStructure: string;
  sentenceAnalysis: string;
}

const mobileHeaders: HeaderData<TextReadingTableRow>[] = [
  { name: 'Start Date', property: 'startDate', isSortable: true },
  { name: 'Title of Book', property: 'bookTitle', isSortable: false },
  { name: 'Book Level', property: 'bookLevel', isSortable: false },
];

const StudentTextReadingTable = (props: { unitName: string | null }) => {
  const { studentProfileId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [query, setQuery] = useState<GetStudentTextReadingsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentTextReadingsQuerySortKeyEnum.StartDate,
    sortDirection: OrderBy.Desc,
  });

  const [results, setResults] = useState<GenericPaginatedResponse<TextReadingTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  useGetTextReadings(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapResponseToTableRows(response.data),
      });
    },
  });

  const mapResponseToTableRows = (textReadings: HootTextReadingResponse[]): TextReadingTableRow[] => {
    return textReadings.map((x): TextReadingTableRow => {
      return {
        assessmentId: x.assessmentId,
        startDate: x.startDate ? DateTime.fromMillis(x.startDate).toFormat(DateFormats.FULL_MDY_0) : '-',
        bookTitle: x.bookTitle ?? '-',
        bookLevel: x.bookLevel ?? '-',
        fluency: x.fluency ?? '-',
        vocabulary: x.vocabulary ?? '-',
        textSpecificKnowledge: x.textSpecificKnowledge ?? '-',
        verbalReasoning: x.verbalReasoning ?? '-',
        textStructure: x.textStructure ?? '-',
        sentenceAnalysis: x.sentenceAnalysis ?? '-',
      };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof TextReadingTableRow) => {
    const getQueryOrderByColumn = (): StudentTextReadingsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'startDate':
          return StudentTextReadingsQuerySortKeyEnum.StartDate;
        default:
          return StudentTextReadingsQuerySortKeyEnum.StartDate;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      sortDirection:
        queryOrderByColumn !== currentState.orderBy ? OrderBy.Asc : currentState.sortDirection === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function orderByColumn(): keyof TextReadingTableRow {
    switch (query.orderBy) {
      case StudentTextReadingsQuerySortKeyEnum.StartDate:
        return 'startDate';
      default:
        return 'startDate';
    }
  }

  const unitIsReadingFluency = props.unitName === 'Reading Fluency';

  const headers: HeaderData<TextReadingTableRow>[] = [
    { name: 'Start Date', property: 'startDate', isSortable: true, width: '15%' },
    { name: 'Title of Book', property: 'bookTitle', isSortable: false, width: '15%' },
    { name: 'Book Level', property: 'bookLevel', isSortable: false, width: '10%' },
    { name: 'Fluency', property: 'fluency', isSortable: false, width: '10%', isHidden: !unitIsReadingFluency },
    { name: 'Vocabulary', property: 'vocabulary', isSortable: false, width: '10%', isHidden: unitIsReadingFluency },
    { name: 'Text-Specific Knowledge', property: 'textSpecificKnowledge', isSortable: false, width: '10%', isHidden: unitIsReadingFluency },
    { name: 'Verbal Reasoning', property: 'verbalReasoning', isSortable: false, width: '10%', isHidden: unitIsReadingFluency },
    { name: 'Text Structure', property: 'textStructure', isSortable: false, width: '10%', isHidden: unitIsReadingFluency },
    { name: 'Sentence Analysis', property: 'sentenceAnalysis', isSortable: false, width: '10%', isHidden: unitIsReadingFluency },
  ];

  if (!results.data) {
    return null;
  }

  return (
    <div style={{ marginTop: '16px' }}>
      <TableV2
        isPaginated
        isSortable
        data={results.data}
        headers={isMobile ? mobileHeaders : headers}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={results.count}
        page={query.page - 1}
        onPageChange={handleChangePage}
        onSortBy={handleSortBy}
        sortBy={orderByColumn()}
        sortOrder={query.sortDirection ?? OrderBy.Asc}
        rowsPerPage={query.pageSize}
        emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
      />
    </div>
  );
};

export default StudentTextReadingTable;
