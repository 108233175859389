import { FrontEndFacingScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';

export interface DistrictRepresentativeScheduleQuery {
  timeZone: string;
  name?: string;
  enrolmentId?: string;
  status?: FrontEndFacingScheduledLessonStatus;
  time?: string;
  school?: string;
  date?: string;
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: OrderBy;
}

export interface ScheduleResponse {
  id: string;
  name: string;
  date: number;
  time: string;
  duration: string;
  status: string;
  school: string;
  enrolmentId: string;
}

export interface DistrictRepresentativeScheduleResponse {
  schedule: ScheduleResponse[];
  count: number;
  page: number;
  pageSize: number;
}

async function searchDistrictRepresentativeScheduleResponse(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeScheduleQuery,
): Promise<DistrictRepresentativeScheduleResponse> {
  const { data } = await axios.get<DistrictRepresentativeScheduleResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/schedule/search`,
    { params: searchParams },
  );

  return data;
}

export default function useSearchDistrictRepresentativeScheduleResponse(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeScheduleQuery,
  options?: Omit<UseQueryOptions<DistrictRepresentativeScheduleResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresentativeSchedule, districtRepresentativeId, searchParams],
    () => searchDistrictRepresentativeScheduleResponse(districtRepresentativeId, searchParams),
    options,
  );
}
