import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';
import { DistrictRepLessonsQuery } from './useDistrictRepMonthlyEnrolmentsLessonsQuery';

export interface WeeklyDistrictRepScheduledLessonResults {
  lessonDate: string;
  lessonStatus: ScheduledLessonStatus;
  startsAt: number;
}

export interface WeeklyDistrictRepLessonsQueryResponse {
  lessons: WeeklyDistrictRepScheduledLessonResults[];
}

export async function fetchDistrictRepEnrolmentsLessonsList(districtRepId: string, query: DistrictRepLessonsQuery) {
  const { data } = await axios.post<WeeklyDistrictRepLessonsQueryResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepId}/lessons/weekly-lessons/search`,
    query,
  );
  return data;
}

export default function useWeeklyDistrictRepScheduledLessons(districtRepId: string, districtRepLessonsQuery: DistrictRepLessonsQuery | undefined) {
  return useQuery(
    [QueryKey.WeeklyDistrictRepLessons, districtRepLessonsQuery],
    () => fetchDistrictRepEnrolmentsLessonsList(districtRepId, districtRepLessonsQuery!),
    { enabled: !!districtRepId && !!districtRepLessonsQuery },
  );
}
