import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { TeacherShiftStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Box, Stack, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useGetTeacherShifts } from '@hoot/hooks/api/availability/useGetTeacherShifts';
import { useGetTeacherAvailabilityExceptions } from '@hoot/hooks/api/availability/useTeacherAvailabilityException';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { CalendarV3 } from '@hoot/ui/components/v2/schedule/CalendarV3';
import { CreateTimeOffRequestDialog } from '@hoot/ui/components/v2/schedule/CreateTimeOffRequestDialog';
import {
  WEEKLY_SCHEDULED_CELL_HEIGHT,
  WeeklySchedule,
  ZIndexLayer,
  convertTimeToOffset,
  findOverlappingTimeslots,
  weeklySchedulePulseAnimation,
} from '@hoot/ui/components/v2/schedule/WeeklySchedule';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { chunkTimeSpan } from '@hoot/utils/date';
import { isBetween } from '@hoot/utils/isBetween';
import useTeacherAvailabilityQuery from '../../../../../hooks/api/availability/useTeacherAvailability';
import useGetTeacherLessons from '../../../../../hooks/api/lesson/useGetTeacherLessons';
import useTeacherMonthlyScheduledLessonsQuery from '../../../../../hooks/api/lesson/useTeacherMonthlyScheduledLessonsQuery';
import SubHeader from '../../../../components/v2/SubHeader';
import Card from '../../../../components/v2/core/Card';
import Page from '../../../../components/v2/core/Page';
import { TeacherScheduleDrawer } from './TeacherScheduleDrawer';
import { TimeOffAlert } from './TimeOffAlert';

enum TeacherScheduleTab {
  WeeklyView = 0,
  MonthlyView = 1,
}

const TIME_FORMAT = 'h:mm a';
const ONE_MINUTE_PX_HEIGHT = WEEKLY_SCHEDULED_CELL_HEIGHT / 30;

const _AVAILABILITY_EXCEPTION_COLOR = hootTokens.palette.warning[160];
const AVAILABILITY_COLOR = hootTokens.palette.neutral[160];
const SCHEDULED_COLOR = hootTokens.palette.secondary[160];
const CANCELLED_COLOR = hootTokens.palette.error[120];
const COMPLETED_COLOR = hootTokens.palette.success[160];

/** INTERFACES  */

export type ShiftStatus = 'SCHEDULED' | 'COMPLETED' | 'CANCELLED' | 'PUBLISHED';

export interface SelectedItem {
  ids: string[];
  type: 'LESSON' | 'SHIFT';
}

export interface ShiftTimeSlot {
  id: string;
  startAt: DateTime;
  endsAt: DateTime;
  status: ShiftStatus;
}

export interface AvailabilityTimeSlot {
  startAt: string;
  endAt: string;
  zone: string;
  dayOfWeek: number;
}

export interface LessonTimeSlot {
  id: string;
  prefixedStudentNumber: string;
  prefixedLessonNumber: string;
  startAt: DateTime;
  endsAt: DateTime;
  status: ScheduledLessonStatus;
}

export interface AvailabilityExceptionSlot {
  startsAt: DateTime;
  endsAt: DateTime;
}

export function TeacherScheduleV2() {
  const [tab, setTab] = useState<TeacherScheduleTab>(TeacherScheduleTab.WeeklyView);
  const [showCancelledEvents, setShowCancelledEvents] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getUser } = useAuth();
  const user = getUser();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showNewEventDialog, setShowNewEventDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateTime>();

  useEffect(() => {
    if (isMobile) {
      setTab(TeacherScheduleTab.WeeklyView);
    }
  }, [isMobile]);

  const handleTabChange = (_event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleScheduleDrawerClose = () => {
    setShowDrawer(false);
  };

  const handleDateClick = (date: DateTime) => () => {
    setSelectedDate(date);
    setShowDrawer(true);
  };

  const handleShowCancelledEventsClick = () => {
    setShowCancelledEvents((currentState) => !currentState);
  };

  const handleNewEventClick = () => {
    setShowNewEventDialog(true);
  };

  const handleCreateTimeOffRequestDialogClose = () => {
    setShowNewEventDialog(false);
  };

  const handleEditMyAvailability = () => {
    window.open(
      `https://forms.zohopublic.com/hootreading1/form/AvailabilityChangeRequestForm1/formperma/RPQ4PO6GC8D0afWM0w86f-7BICSe4kfYncAcsjkYOxo?Name_First=${user.firstName}&Name_Last=${user.lastName}&Email=${user.email}`,
      '_blank',
    );
  };

  return (
    <Page data-sentry-mask pageTitle="Schedule" noPadding noMaxWidth>
      <SubHeader
        title={{
          label: 'Schedule',
          isPII: false,
        }}
        secondaryAction={
          isMobile
            ? undefined
            : {
                label: 'Edit My Availability',
                onClick: handleEditMyAvailability,
                props: {
                  startIcon: <Icon name="new_tab" />,
                },
              }
        }
      />

      {/* If it's mobile, we will hide tabs */}
      {isMobile ? null : (
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" sx={{ backgroundColor: hootTokens.palette.white }}>
          <Tab label="Week view" value={TeacherScheduleTab.WeeklyView} isSelected={tab === TeacherScheduleTab.WeeklyView} />
          <Tab label="Month view" value={TeacherScheduleTab.MonthlyView} isSelected={tab === TeacherScheduleTab.MonthlyView} />
        </Tabs>
      )}

      <TimeOffAlert />

      <Stack>
        {tab === TeacherScheduleTab.WeeklyView ? (
          <WeeklyView
            onDateClick={handleDateClick}
            onNewEventClick={isMobile ? undefined : handleNewEventClick}
            showCancelledEvents={showCancelledEvents}
            onShowCancelledEventsClick={handleShowCancelledEventsClick}
          />
        ) : null}
        {tab === TeacherScheduleTab.MonthlyView ? (
          <MonthlyView
            onDateClick={handleDateClick}
            showCancelledEvents={showCancelledEvents}
            onShowCancelledEventsClick={handleShowCancelledEventsClick}
            onNewEventClick={handleNewEventClick}
          />
        ) : null}
      </Stack>
      {showDrawer ? (
        <TeacherScheduleDrawer
          onShowCancelledEventsClick={handleShowCancelledEventsClick}
          showCancelledEvents={showCancelledEvents}
          open={showDrawer}
          onClose={handleScheduleDrawerClose}
          selectedDate={selectedDate}
        />
      ) : null}
      <CreateTimeOffRequestDialog
        key={`${showNewEventDialog}-create-time-off`}
        open={showNewEventDialog}
        onClose={handleCreateTimeOffRequestDialogClose}
      />
    </Page>
  );
}

function WeeklyView(props: {
  onNewEventClick?: () => void;
  onDateClick: (date: DateTime) => () => void;
  showCancelledEvents: boolean;
  onShowCancelledEventsClick: () => void;
}) {
  const [startOfWeek, setStartOfWeek] = useState<DateTime>(DateTime.now().startOf('week'));
  const [showMyAvailability, setShowMyAvailability] = useState(true);
  const [useStudentNumbers, setUseStudentNumbers] = useState(false);

  const startDate = startOfWeek.toMillis();
  const endDate = startOfWeek.endOf('week').toMillis();

  const availabilityRequest = useTeacherAvailabilityQuery();

  const availabilityExceptionsRequest = useGetTeacherAvailabilityExceptions();

  const lessonsRequest = useGetTeacherLessons({
    fromDate: startDate,
    toDate: endDate,
  });

  const getTeacherShiftsRequest = useGetTeacherShifts({
    startsAt: startDate,
    endsAt: endDate,
  });

  const handleSelectedItem = (dayOfWeek: DateTime) => {
    props.onDateClick(dayOfWeek)();
  };

  const handleStartOfWeekChange = (updatedVal: DateTime) => {
    setStartOfWeek(updatedVal);
  };

  const handleShowMyAvailabilityClick = () => {
    setShowMyAvailability((currentState) => !currentState);
  };

  const handleUseStudentNumbersClick = () => {
    setUseStudentNumbers((currentState) => !currentState);
  };

  const lessons =
    lessonsRequest.data?.lessons.map<LessonTimeSlot>((l) => ({
      id: l.id,
      prefixedStudentNumber: l.prefixedStudentNumber,
      prefixedLessonNumber: l.prefixedLessonNumber,
      startAt: DateTime.fromMillis(l.startsAt),
      endsAt: DateTime.fromMillis(l.startsAt).plus({ minute: l.durationInMinutes }),
      status: l.status,
    })) ?? [];

  const shifts =
    getTeacherShiftsRequest.data?.shifts?.map<ShiftTimeSlot>((s) => ({
      id: s.id,
      startAt: DateTime.fromMillis(s.startsAt),
      endsAt: DateTime.fromMillis(s.endsAt),
      status: s.status,
    })) ?? [];

  const availability =
    availabilityRequest.data?.timeblocks?.map<AvailabilityTimeSlot>((a) => ({
      dayOfWeek: a.dayOfWeek,
      startAt: a.startTime,
      endAt: a.endTime,
      zone: availabilityRequest.data.timezone,
    })) ?? [];

  const availabilityExceptions =
    availabilityExceptionsRequest?.data
      ?.flatMap((ae) => chunkTimeSpan(DateTime.fromMillis(ae.startsAt), DateTime.fromMillis(ae.endsAt)))
      ?.map<AvailabilityExceptionSlot>((ae) => ({
        startsAt: ae.startsAt,
        endsAt: ae.endsAt,
      })) ?? [];

  const isLoading =
    availabilityRequest.isFetching || availabilityExceptionsRequest.isFetching || lessonsRequest.isLoading || getTeacherShiftsRequest.isLoading;

  const ColumnDetails = (dayOfWeek: DateTime) => {
    const lessonsToDisplay = props.showCancelledEvents
      ? lessons
      : lessons.filter((l) => ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.status));
    return (
      <WeeklyColumnDetails
        dayOfWeek={dayOfWeek}
        availability={showMyAvailability ? availability : []}
        availabilityExceptions={availabilityExceptions}
        shifts={shifts}
        lessons={lessonsToDisplay}
        onSelected={handleSelectedItem}
        useStudentNumbers={useStudentNumbers}
      />
    );
  };

  return (
    <Stack padding="16px">
      <Card isLoading={false}>
        <WeeklySchedule
          isLoading={isLoading}
          startOfWeek={startOfWeek}
          onStartOfWeekChange={handleStartOfWeekChange}
          onSelected={handleSelectedItem}
          onNewEventClick={props.onNewEventClick}
          columnDetails={ColumnDetails}
          footerFunction={
            <WeeklyViewFooter
              showMyAvailability={showMyAvailability}
              showCancelledEvents={props.showCancelledEvents}
              useStudentNumbers={useStudentNumbers}
              onShowMyAvailabiltyClick={handleShowMyAvailabilityClick}
              onShowCancelledEventsClick={props.onShowCancelledEventsClick}
              onUseStudentNumbersClick={handleUseStudentNumbersClick}
            />
          }
        />
      </Card>
    </Stack>
  );
}

function WeeklyColumnDetails(props: {
  dayOfWeek: DateTime;
  availability: AvailabilityTimeSlot[];
  availabilityExceptions: AvailabilityExceptionSlot[];
  shifts: ShiftTimeSlot[];
  lessons: LessonTimeSlot[];
  selectedItem?: SelectedItem;
  onSelected: (dayOfWeek: DateTime) => void;
  useStudentNumbers?: boolean;
}) {
  const endOfDay = props.dayOfWeek.endOf('day');

  const shifts = props.shifts?.filter((s) => s.startAt >= props.dayOfWeek && s.startAt <= endOfDay) ?? [];
  const lessons = props.lessons?.filter((s) => s.startAt >= props.dayOfWeek && s.startAt <= endOfDay) ?? [];
  const availability = props.availability?.filter((a) => a.dayOfWeek === props.dayOfWeek.weekday) ?? [];
  const availabilityExceptions = props.availabilityExceptions?.filter((s) => s.startsAt >= props.dayOfWeek && s.startsAt <= endOfDay) ?? [];

  const overlappingLessons = findOverlappingTimeslots(lessons);

  return (
    <>
      {availability.map((a) => (
        <Availability key={`${a.startAt}-${a.endAt}-${a.dayOfWeek}`} startsAt={a.startAt} endsAt={a.endAt} zone={a.zone} />
      ))}
      {availabilityExceptions?.map((ae) => (
        <AvailabilityException startsAt={ae.startsAt.toFormat(TIME_FORMAT)} endsAt={ae.endsAt.toFormat(TIME_FORMAT)} />
      ))}
      {shifts.map((s) => (
        <WeeklyShift
          key={s.id}
          id={s.id}
          selectedItem={props.selectedItem}
          startsAt={s.startAt.toFormat(TIME_FORMAT)}
          endsAt={s.endsAt.toFormat(TIME_FORMAT)}
          status={s.status}
        />
      ))}
      {overlappingLessons.map((l) => (
        <Lesson
          key={l.map((lesson) => lesson.id).join('-')}
          dayOfWeek={props.dayOfWeek}
          selectedItem={props.selectedItem}
          onSelected={props.onSelected}
          timeslots={l}
          useStudentNumbers={props.useStudentNumbers}
        />
      ))}
    </>
  );
}

function Availability(props: { startsAt: string; endsAt: string; zone: string }) {
  const startTime = DateTime.fromFormat(props.startsAt, TIME_FORMAT, { zone: props.zone });
  const endsAt = DateTime.fromFormat(props.endsAt, TIME_FORMAT, { zone: props.zone });

  const localStartTime = startTime.setZone(DateTime.local().zoneName);
  const localEndsAt = endsAt.setZone(DateTime.local().zoneName);

  const top = Math.max(convertTimeToOffset(localStartTime.toFormat(TIME_FORMAT)), 0);
  const height = convertTimeToOffset(localEndsAt.toFormat(TIME_FORMAT)) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          borderRadius: '4px',
          height: '100%',
          width: '100%',
          backgroundColor: hootTokens.palette.neutral['180'],
          border: `solid 2px ${hootTokens.palette.black}`,
        }}
      />
    </Stack>
  );
}

function AvailabilityException(props: { startsAt: string; endsAt: string }) {
  const startTime = DateTime.fromFormat(props.startsAt, TIME_FORMAT);
  const endsAt = DateTime.fromFormat(props.endsAt, TIME_FORMAT);

  const top = Math.max(convertTimeToOffset(startTime.toFormat(TIME_FORMAT)), 0);
  const height = convertTimeToOffset(endsAt.toFormat(TIME_FORMAT)) - top;

  return (
    <Stack
      sx={{
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
        width: '100%',
        paddingX: '4px',
        position: 'absolute',
        zIndex: ZIndexLayer.Bottom,
      }}
    >
      <Stack
        sx={{
          borderRadius: '4px',
          height: '100%',
          width: '100%',
          backgroundColor: hootTokens.palette.error['180'],
          border: `solid 2px ${hootTokens.palette.error['100']}`,
        }}
      />
    </Stack>
  );
}

function WeeklyShift(props: { id: string; startsAt: string; endsAt: string; status: ShiftStatus; selectedItem?: SelectedItem }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const top = Math.max(convertTimeToOffset(props.startsAt), 0);
  const height = convertTimeToOffset(props.endsAt) - top;

  const isSelected = props.selectedItem?.ids.some((id) => id === props.id) && props.selectedItem.type === 'SHIFT';

  return (
    <Stack
      sx={{
        position: 'absolute',
        left: isMobile ? '8px' : '16px',
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        zIndex: ZIndexLayer.Middle,
      }}
    >
      <Stack
        sx={{
          backgroundColor: shiftColor(props.status),
          height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
          width: '8px',
          borderRadius: '4px',
          boxShadow: isSelected ? '0px 2px 6px 2px #FF8E00, 0px 1px 2px 0px #FF8E00' : undefined,
        }}
      />

      {isSelected ? (
        <Stack
          sx={{
            position: 'absolute',
            height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
            width: '8px',
            borderRadius: '4px',
            border: '3px solid #CBA82C',
            animation: `${weeklySchedulePulseAnimation} 2s ease 0s infinite`,
          }}
        />
      ) : null}
    </Stack>
  );
}

function Lesson(props: {
  dayOfWeek: DateTime;
  selectedItem?: SelectedItem;
  onSelected: (dayOfWeek: DateTime) => void;
  timeslots: LessonTimeSlot[];
  useStudentNumbers?: boolean;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const minStartsAt = DateTime.fromMillis(Math.min(...props.timeslots.map((ts) => ts.startAt.toMillis()))).toFormat(TIME_FORMAT);
  const maxEndsAt = DateTime.fromMillis(Math.max(...props.timeslots.map((ts) => ts.endsAt.toMillis()))).toFormat(TIME_FORMAT);

  const top = Math.max(convertTimeToOffset(minStartsAt), 0);
  const height = convertTimeToOffset(maxEndsAt) - top;

  const handleClick = () => {
    props.onSelected(props.dayOfWeek);
  };

  const isSelected =
    props.selectedItem && props.selectedItem.ids.some((id) => props.timeslots.some((i) => i.id === id)) && props.selectedItem.type === 'LESSON';

  function label() {
    if (isMobile) {
      return undefined;
    }
    if (props.timeslots.length === 1) {
      const timeslot = props.timeslots[0];
      return props.useStudentNumbers ? timeslot.prefixedStudentNumber : timeslot.prefixedLessonNumber;
    } else {
      return `Multiple (${props.timeslots.length})`;
    }
  }

  function backgroundColor() {
    if (props.timeslots.length === 1) {
      return lessonColor(props.timeslots[0].status);
    } else {
      if (
        props.timeslots.some(
          (ts) => ts.status === ScheduledLessonStatus.CompletedSuccessfully || ts.status === ScheduledLessonStatus.CompletedUnsuccessfully,
        )
      ) {
        return lessonColor(ScheduledLessonStatus.CompletedSuccessfully);
      } else if (
        props.timeslots.some(
          (ts) =>
            ts.status === ScheduledLessonStatus.InProgress ||
            ts.status === ScheduledLessonStatus.Open ||
            ts.status === ScheduledLessonStatus.OpenWaitingForStudent ||
            ts.status === ScheduledLessonStatus.OpenWaitingForTeacher ||
            ts.status === ScheduledLessonStatus.Scheduled,
        )
      ) {
        return lessonColor(ScheduledLessonStatus.Scheduled);
      } else {
        return lessonColor(ScheduledLessonStatus.Cancelled);
      }
    }
  }

  function backgroundImage() {
    if (props.timeslots.some((ts) => ts.status === ScheduledLessonStatus.Cancelled)) {
      if (props.timeslots.some((ts) => ts.status === ScheduledLessonStatus.Scheduled)) {
        return `-webkit-linear-gradient(30deg, ${lessonColor(ScheduledLessonStatus.Scheduled)} 50%, ${lessonColor(
          ScheduledLessonStatus.Cancelled,
        )} 50%);`;
      }
    }

    return undefined;
  }

  return (
    <Stack
      onClick={handleClick}
      sx={{
        position: 'absolute',
        zIndex: ZIndexLayer.Middle,
        left: '32px',
        right: '16px',
        top: `${top * ONE_MINUTE_PX_HEIGHT}px`,
        cursor: 'pointer',
      }}
    >
      <Stack
        sx={{
          paddingX: '8px',
          borderRadius: '8px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          color: hootTokens.palette.black,
          height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
          backgroundColor: backgroundColor(),
          backgroundImage: backgroundImage(),
          boxShadow: isSelected ? '0px 2px 6px 2px #FF8E00, 0px 1px 2px 0px #FF8E00' : undefined,
        }}
      >
        <Typography variant="bodysmall">{label()}</Typography>
      </Stack>
      {isSelected ? (
        <Stack
          sx={{
            position: 'absolute',
            height: `${height * ONE_MINUTE_PX_HEIGHT}px`,
            width: '70px',
            borderRadius: '8px',
            border: '3px solid #CBA82C',
            animation: `${weeklySchedulePulseAnimation} 2s ease 0s infinite`,
          }}
        />
      ) : null}
    </Stack>
  );
}

function lessonColor(status: ScheduledLessonStatus): string {
  if (status === ScheduledLessonStatus.Cancelled || status === ScheduledLessonStatus.Rescheduled) {
    return hootTokens.palette.error[120];
  } else if (status === ScheduledLessonStatus.CompletedSuccessfully || status === ScheduledLessonStatus.CompletedUnsuccessfully) {
    return hootTokens.palette.success[120];
  } else {
    return hootTokens.palette.secondary[120];
  }
}

function WeeklyViewFooter(props: {
  showMyAvailability: boolean;
  showCancelledEvents: boolean;
  useStudentNumbers: boolean;
  onShowMyAvailabiltyClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onShowCancelledEventsClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onUseStudentNumbersClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function Rectangle(props: { color: string }) {
    return <Box sx={{ height: '15px', width: '15px', borderRadius: '4px', backgroundColor: props.color }} />;
  }

  return (
    <Stack sx={{ marginTop: '24px', marginLeft: '24px' }} direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
      <Stack direction={isMobile ? 'column' : 'row'}>
        <Checkbox label="Show My Availability" checked={props.showMyAvailability} onClick={props.onShowMyAvailabiltyClick} />
        <Checkbox label="Show Cancelled Events" checked={props.showCancelledEvents} onClick={props.onShowCancelledEventsClick} />
        <Checkbox label="Use Student Numbers" checked={props.useStudentNumbers} onClick={props.onUseStudentNumbersClick} />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} marginTop={isMobile ? '16px' : undefined} spacing="16px">
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={AVAILABILITY_COLOR} />
          <Typography variant="bodysmall"> Availability</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={SCHEDULED_COLOR} />
          <Typography variant="bodysmall">Published/Scheduled</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={COMPLETED_COLOR} />
          <Typography variant="bodysmall">Completed</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={CANCELLED_COLOR} />
          <Typography variant="bodysmall">Cancelled</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

// ===== Monthly Schedule functions =====
function MonthlyView(props: {
  onDateClick: (date: DateTime) => () => void;
  showCancelledEvents: boolean;
  onShowCancelledEventsClick: () => void;
  onNewEventClick: () => void;
}) {
  const [month, setMonth] = useState<DateTime>(DateTime.now().startOf('month'));

  const getTeacherShiftsRequest = useGetTeacherShifts({
    startsAt: month.startOf('month').startOf('week').minus({ day: 1 }).toMillis(),
    endsAt: month.endOf('month').endOf('week').minus({ day: 1 }).toMillis(),
  });

  const availablityExceptionsRequest = useGetTeacherAvailabilityExceptions();
  const availablityExeptionDates = availablityExceptionsRequest.data?.flatMap((exception) =>
    chunkTimeSpan(DateTime.fromMillis(exception.startsAt), DateTime.fromMillis(exception.endsAt)),
  );

  const monthlyScheduledLessons = useTeacherMonthlyScheduledLessonsQuery({ dateInMonth: month.toMillis() });

  const handleMonthChange = (newVal: DateTime) => {
    setMonth(newVal);
  };

  const OnRenderCalendarDate = (date: DateTime) => {
    if (!monthlyScheduledLessons.data || !getTeacherShiftsRequest.data) {
      return null;
    }

    const lessonCount =
      monthlyScheduledLessons.data.lessonDates
        ?.find((ld) => ld.date === date.toSQLDate())
        ?.dayDetails.statuses.filter((s) => (props.showCancelledEvents ? s : s.status !== ScheduledLessonStatus.Cancelled))
        ?.map((d) => parseInt(d.count.toString()))
        ?.reduce((a, b) => a + b, 0) ?? 0;

    const shifts = getTeacherShiftsRequest.data.shifts
      .filter((s) => isBetween(s.startsAt, date.toMillis(), date.endOf('day').toMillis()))
      .sort((a, b) => a.startsAt - b.startsAt);

    const timeoff = availablityExeptionDates
      ?.filter((a) => isBetween(a.startsAt.toMillis(), date.toMillis(), date.endOf('day').toMillis()))
      .sort((a, b) => a.startsAt.toMillis() - b.startsAt.toMillis());

    return (
      <Stack spacing="16px" sx={{ width: '100%' }}>
        {shifts?.map((shift) => (
          <Shift
            status={shift.status}
            startsAt={DateTime.fromMillis(shift.startsAt).toFormat('h:mm a')}
            endsAt={DateTime.fromMillis(shift.endsAt).toFormat('h:mm a')}
          />
        ))}
        {lessonCount ? <LessonContainer lessonCount={lessonCount} /> : null}
        {timeoff?.map((to) => <TimeOff startsAt={to.startsAt.toFormat('h:mm a')} endsAt={to.endsAt.toFormat('h:mm a')} />)}
      </Stack>
    );
  };

  const isLoading = getTeacherShiftsRequest.isFetching || availablityExceptionsRequest.isLoading || monthlyScheduledLessons.isLoading;

  return (
    <Stack padding="16px">
      <Card isLoading={false}>
        <CalendarV3
          isLoading={isLoading}
          month={month}
          onMonthChange={handleMonthChange}
          renderCalendarDate={OnRenderCalendarDate}
          onDateClick={props.onDateClick}
          header={<MonthlyViewHeader onNewEventClick={props.onNewEventClick} />}
          footer={<MonthlyViewFooter showCancelledEvents={props.showCancelledEvents} onShowCancelledEventsClick={props.onShowCancelledEventsClick} />}
        />
      </Card>
    </Stack>
  );
}

function MonthlyViewHeader(props: { onNewEventClick: () => void }) {
  return (
    <Button onClick={props.onNewEventClick} variant="contained" startIcon={<Icon htmlColor={hootTokens.palette.white} name="add" />}>
      New Event
    </Button>
  );
}

function Shift(props: { startsAt: string; endsAt: string; status: TeacherShiftStatus }) {
  return (
    <Stack
      sx={{
        backgroundColor: shiftColor(props.status),
        borderRadius: '8px',
        padding: '8px',
        cursor: 'pointer',
      }}
      alignItems="center"
    >
      <Typography variant="labelsmall">Shift{props.status === TeacherShiftStatus.Cancelled ? ' (Cancelled)' : undefined}</Typography>
      <Typography variant="labelsmall">
        {props.startsAt} - {props.endsAt}
      </Typography>
    </Stack>
  );
}

function LessonContainer(props: { lessonCount: number }) {
  return (
    <Stack
      sx={{
        backgroundColor: hootTokens.palette.secondary[160],
        boxShadow: hootTokens.elevation.elevation1,
        borderRadius: '8px',
        padding: '8px 24px',
        cursor: 'pointer',
      }}
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing="4px"
    >
      <Typography variant="tableheadingactive">{props.lessonCount}</Typography>
      <Typography variant="labelsmall">Lessons</Typography>
    </Stack>
  );
}

function TimeOff(props: { startsAt: string; endsAt: string }) {
  return (
    <Stack
      sx={{
        backgroundColor: hootTokens.palette.warning[180],
        borderRadius: '8px',
        padding: '8px',
        cursor: 'pointer',
      }}
      alignItems="center"
    >
      <Typography variant="labelsmall">Time Off</Typography>
      <Typography variant="labelsmall">
        {props.startsAt} - {props.endsAt}
      </Typography>
    </Stack>
  );
}

function shiftColor(status: ShiftStatus): string {
  switch (status) {
    case 'CANCELLED':
      return hootTokens.palette.error[120];
    case 'COMPLETED':
      return hootTokens.palette.success[120];
    case 'SCHEDULED':
    case 'PUBLISHED':
      return hootTokens.palette.secondary[120];
  }
}

function MonthlyViewFooter(props: { showCancelledEvents: boolean; onShowCancelledEventsClick: (e: React.MouseEvent<HTMLButtonElement>) => void }) {
  function Rectangle(props: { color: string }) {
    return <Box sx={{ height: '15px', width: '15px', borderRadius: '4px', backgroundColor: props.color }} />;
  }

  return (
    <Stack sx={{ marginTop: '24px', marginLeft: '24px' }} direction="row" justifyContent="space-between">
      <Stack direction="row">
        <Checkbox label="Show Cancelled Events" checked={props.showCancelledEvents} onClick={props.onShowCancelledEventsClick} />
      </Stack>
      <Stack direction="row" spacing="16px">
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={SCHEDULED_COLOR} />
          <Typography variant="bodysmall">Scheduled</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={COMPLETED_COLOR} />
          <Typography variant="bodysmall">Completed</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={CANCELLED_COLOR} />
          <Typography variant="bodysmall">Cancelled</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
