import axios from 'axios';
import { useQuery } from 'react-query';
import { LessonDatesStats } from '@hoot/models/api/lessons';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepLessonsQuery {
  startDate: number;
  endDate: number;
  studentProfileId?: string;
}

export interface DistrictRepMonthlyEnrolmentsLessonsQueryResponse {
  lessonDates: LessonDatesStats[];
}

export async function fetchDistrictRepMonthlyEnrolmentsLessonsList(districtRepId: string, query: DistrictRepLessonsQuery) {
  const { data } = await axios.post<DistrictRepMonthlyEnrolmentsLessonsQueryResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepId}/lessons/search`,
    query,
  );
  return data;
}

export default function useDistrictRepMonthlyScheduledLessonsQuery(
  districtRepId: string,
  districtRepLessonsQuery: DistrictRepLessonsQuery | undefined,
) {
  return useQuery(
    [QueryKey.DistrictRepMonthlyEnrolmentsLessons, districtRepLessonsQuery],
    () => fetchDistrictRepMonthlyEnrolmentsLessonsList(districtRepId, districtRepLessonsQuery!),
    { enabled: !!districtRepId && !!districtRepLessonsQuery },
  );
}
