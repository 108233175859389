import { generatePath } from 'react-router-dom';

export const routesDictionary = {
  home: {
    path: '/',
    url: '/',
  },
  login: {
    path: 'login',
    url: '/login',
    district: {
      path: ':friendlyId',
      url: (friendlyId: string) => generatePath('/login/:friendlyId', { friendlyId }),
      absolutePath: '/login/:friendlyId',
    },
  },
  publicStudentSchedule: {
    path: 'student-schedule',
    url: '/student-schedule',
    location: {
      path: ':locationId',
      url: (locationId: string) => generatePath('/student-schedule/:locationId', { locationId: locationId }),
      absolutePath: '/studentSchedule/:locationId/',
    },
  },
  availability: {
    path: 'availability',
    url: '/availability',
    label: 'Availability',
  },
  schedule: {
    path: 'schedule',
    url: '/schedule',
    label: 'Schedule',
    details: {
      path: 'details',
      url: '/schedule/details',
      label: 'Details',
    },
  },
  opportunities: {
    path: 'opportunities',
    url: '/opportunities',
    label: 'Opportunities',
  },
  invoicing: {
    path: 'invoicing',
    url: '/invoicing',
    label: 'Invoicing',
    details: {
      path: 'details',
      url: (invoiceId: string) => generatePath('/invoicing/details/:invoiceId', { invoiceId }),
      label: 'Details',
    },
  },
  profile: {
    path: 'profile',
    url: '/profile',
    label: 'Profile',
  },
  selectProfile: {
    path: 'select-profile',
    url: '/select-profile',
  },
  lessonTasks: {
    path: 'lesson-tasks',
    url: '/lesson-tasks',
    label: 'Lesson Tasks',
    readingAssessment: {
      path: 'reading-assessment',
      url: (studentProfileId: string) => generatePath('/lesson-tasks/student-profile/:studentProfileId/reading-assessment', { studentProfileId }),
      new: {
        path: 'new',
        url: (studentProfileId: string) =>
          generatePath('/lesson-tasks/student-profile/:studentProfileId/reading-assessment/new', { studentProfileId }),
      },
      view: {
        path: ':assessmentId',
        url: (studentProfileId: string, assessmentId: string) =>
          generatePath('/lesson-tasks/student-profile/:studentProfileId/reading-assessment/:assessmentId', {
            studentProfileId,
            assessmentId,
          }),
      },
    },
  },
  library: {
    path: 'library',
    url: '/library',
    absolutePath: '/library',
    label: 'Library',
    book: {
      path: ':bookId',
      absolutePath: `/library/:bookId`,
      url: (bookId: string) => generatePath('/library/:bookId', { bookId }),
    },
  },
  lesson: {
    path: 'lesson/:lessonId',
    absolutePath: '/lesson/:lessonId',
    url: (lessonId: string) => generatePath('/lesson/:lessonId', { lessonId }),
    library: {
      path: 'library',
      url: (lessonId: string) =>
        generatePath('/lesson/:lessonId/library', {
          lessonId,
        }),
      book: {
        path: ':bookId',
        absolutePath: '/lesson/:lessonId/library/:bookId',
        url: (lessonId: string, bookId: string) =>
          generatePath('/lesson/:lessonId/library/:bookId', {
            lessonId,
            bookId,
          }),
      },
      soloBook: {
        path: 'solo-book/:bookId',
        url: (lessonId: string, bookId: string) =>
          generatePath('/lesson/:lessonId/library/solo-book/:bookId', {
            lessonId,
            bookId,
          }),
      },
    },
    whiteboard: {
      path: 'whiteboard',
      url: (lessonId: string) =>
        generatePath('/lesson/:lessonId/whiteboard', {
          lessonId,
        }),
    },
    progressMonitoringAssessment: {
      path: 'progress-monitoring-assessment',
      absolutePath: '/lesson/:lessonId/progress-monitoring-assessment',
      url: (lessonId: string) =>
        generatePath('/lesson/:lessonId/progress-monitoring-assessment', {
          lessonId,
        }),
    },

    hootReadingAssessment: {
      path: 'hoot-reading-assessment',
      absolutePath: '/lesson/:lessonId/hoot-reading-assessment',
      url: (lessonId: string) =>
        generatePath('/lesson/:lessonId/hoot-reading-assessment', {
          lessonId,
        }),
    },
  },
  logout: {
    path: 'logout',
    url: '/logout',
    label: 'Logout',
  },
  impersonateLogin: {
    path: 'impersonate-login',
    url: '/impersonate-login',
  },
  dashboard: {
    path: 'dashboard',
    url: '/dashboard',
    label: 'Dashboard',
  },
  scopeAndSequence: {
    path: 'scope-and-sequence',
    url: '/scope-and-sequence',
    label: 'Scope & Sequence',
    focusArea: {
      path: ':focusAreaId',
      url: (focusAreaId: string) => generatePath('/scope-and-sequence/:focusAreaId', { focusAreaId }),
      unit: {
        path: ':unitSlug',
        url: (focusAreaId: string, unitSlug: string) =>
          generatePath('/scope-and-sequence/:focusAreaId/:unitSlug', {
            focusAreaId,
            unitSlug,
          }),
      },
    },
  },
  // For district reps
  enrolments: {
    path: 'enrolments',
    url: '/enrolments',
    label: 'Enrolments',
    enrolment: {
      path: 'enrolment/:enrolmentId',
      details: {
        path: 'details',
        url: (enrolmentId: string) => generatePath('/enrolments/enrolment/:enrolmentId/details', { enrolmentId }),
      },
      students: {
        path: 'students',
        url: (enrolmentId: string) => generatePath('/enrolments/enrolment/:enrolmentId/students', { enrolmentId }),
      },
      teamMembers: {
        path: 'team-members',
        url: (enrolmentId: string) => generatePath('/enrolments/enrolment/:enrolmentId/team-members', { enrolmentId }),
      },
      schools: {
        path: 'schools',
        url: (enrolmentId: string) => generatePath('/enrolments/enrolment/:enrolmentId/schools', { enrolmentId }),
      },
    },
    product: {
      path: 'product/:productId',
      details: {
        path: 'details',
        url: (productId: string) => generatePath('/enrolments/product/:productId/details', { productId }),
      },
    },
  },
  schools: {
    path: 'schools',
    url: '/schools',
    label: 'Schools',
    school: {
      path: ':schoolId',
      students: {
        path: 'students',
        url: (schoolId: string) => generatePath('/schools/:schoolId/students', { schoolId }),
      },
      enrolments: {
        path: 'enrolments',
        url: (schoolId: string) => generatePath('/schools/:schoolId/enrolments', { schoolId }),
      },
      reporting: {
        path: 'reporting',
        url: (schoolId: string) => generatePath('/schools/:schoolId/reporting', { schoolId }),
      },
    },
  },
  students: {
    path: 'students',
    url: '/students',
    label: 'Students',
    student: {
      path: ':studentProfileId',
      details: {
        path: 'details',
        url: (studentProfileId: string) => generatePath('/students/:studentProfileId/details', { studentProfileId }),
      },
      schedule: {
        path: 'schedule',
        url: (studentProfileId: string) => generatePath('/students/:studentProfileId/schedule', { studentProfileId }),
      },
      enrolments: {
        path: 'enrolments',
        url: (studentProfileId: string) => generatePath('/students/:studentProfileId/enrolments', { studentProfileId }),
      },
      reporting: {
        path: 'reporting',
        url: (studentProfileId: string) => generatePath('/students/:studentProfileId/reporting', { studentProfileId }),
      },
    },
  },
  teamMembers: {
    path: 'team-members',
    url: '/team-members',
    label: 'Team Members',
    districtRep: {
      path: ':districtRepId',
      details: {
        path: 'details',
        url: (districtRepId: string) => generatePath('/team-members/:districtRepId/details', { districtRepId }),
      },
    },
  },
  // For teachers
  myStudents: {
    path: 'my-students',
    url: '/my-students',
    label: 'My Students',
    notFound: {
      path: 'not-found',
      url: '/my-students/not-found',
    },
    details: {
      path: ':studentProfileId',
      profile: {
        path: 'profile',
        url: (studentProfileId: string) => generatePath('/my-students/:studentProfileId/profile', { studentProfileId }),
      },
      reviews: {
        path: 'reviews',
        url: (studentProfileId: string) => generatePath('/my-students/:studentProfileId/reviews', { studentProfileId }),
        lessonReviewId: {
          path: 'reviews/:lessonReviewId',
          url: (studentProfileId: string, lessonReviewId: string) =>
            generatePath('/my-students/:studentProfileId/reviews/:lessonReviewId', {
              studentProfileId,
              lessonReviewId,
            }),
        },
      },
      assessments: {
        path: 'assessments',
        url: (studentProfileId: string) => generatePath('/my-students/:studentProfileId/assessments', { studentProfileId }),
        new: {
          path: 'new',
          url: (studentProfileId: string) => generatePath('/my-students/:studentProfileId/assessments/new', { studentProfileId }),
        },
        resume: {
          path: ':assessmentId',
          url: (studentProfileId: string, assessmentId: string) =>
            generatePath('/my-students/:studentProfileId/assessments/:assessmentId', {
              studentProfileId,
              assessmentId,
            }),
        },
      },
      library: {
        path: 'library',
        url: (studentProfileId: string) => generatePath('/my-students/:studentProfileId/library', { studentProfileId }),
        absolutePath: `/my-students/:studentProfileId/library/`,
        basePath: `/my-students/:studentProfileId/library/*`,
        book: {
          path: ':bookId',
          url: (studentProfileId: string, bookId: string) =>
            generatePath('/my-students/:studentProfileId/library/:bookId', {
              studentProfileId,
              bookId,
            }),
          absolutePath: `/my-students/:studentProfileId/library/:bookId`,
        },
      },
      timeline: {
        path: 'timeline',
        url: (studentProfileId: string) => generatePath('/my-students/:studentProfileId/timeline', { studentProfileId }),
      },
    },
  },
  reporting: {
    path: 'reporting',
    label: 'Reporting',
    url: '/reporting',
  },
  support: {
    url: 'https://hootteachers.zendesk.com/hc/en-us',
  },
  freeLesson: {
    path: 'free-lesson',
    url: '/free-lesson',
    registration: {
      path: 'registration',
      url: '/free-lesson/registration',
    },
    login: {
      path: 'login',
      url: '/free-lesson/login',
    },
  },
  settings: {
    path: 'settings',
    url: '/settings',
    label: 'Settings',
    account: {
      path: 'account',
      url: '/settings/account',
      label: 'Account Settings',
    },
    audioVideo: {
      path: 'audio-video',
      absolutePath: `/settings/audio-video`,
      url: '/settings/audio-video',
      label: 'Audio/Video Settings',
    },
    // When we _are_ logged in, and want to change our password.
    password: {
      path: 'password',
      url: '/settings/password',
      label: 'Change Password',
    },
  },
  // When we're not logged in, and have requested to change password.
  changePassword: {
    path: 'change-password',
  },
  getInTouch: {
    url: 'https://www.hootreading.com/get-in-touch',
  },
};
