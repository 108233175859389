import { useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DateFormats } from '@hoot/constants/constants';
import useGetReviewHistory, { LessonReviewHistoryOrderColumn, LessonReviewHistoryQuery } from '@hoot/hooks/api/lesson-reviews/useGetReviewHistory';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE } from '@hoot/models/api/pagination';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { getLessonReviewLessonTypeLabel } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewDetailsCard';
import LessonNotesDialog from './LessonNotesDialog';

interface ReviewHistoryTableRow {
  date: string;
  lessonType: string;
  focuses: string | null;
  viewButton: React.ReactNode;
}

const LessonReviewHistory = () => {
  const { studentProfileId } = useParams();

  const { getUser } = useAuth();
  const teacherAccountId = getUser().teacherAccount?.id;

  const theme = useTheme();
  const sub600px = useMediaQuery(theme.breakpoints.down('sm'));

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [reviewId, setReviewId] = useState<string>('');
  const [query, setQuery] = useState<LessonReviewHistoryQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: OrderBy.Desc,
    orderColumn: LessonReviewHistoryOrderColumn.Date,
  });

  const reviewData = useGetReviewHistory(studentProfileId!, query);

  const handleViewClick = (noteId: string) => {
    setReviewId(noteId);
    setShowDialog(true);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((prev) => ({ ...prev, page: 1, pageSize }));
  };

  const handleSortBy = (column: keyof ReviewHistoryTableRow) => {
    function orderColumn() {
      switch (column) {
        case 'date':
          return LessonReviewHistoryOrderColumn.Date;
        case 'lessonType':
          return LessonReviewHistoryOrderColumn.LessonType;
        default:
          return LessonReviewHistoryOrderColumn.Date;
      }
    }

    setQuery((prev) => ({
      ...prev,
      orderColumn: orderColumn(),
      orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function sortBy(): keyof ReviewHistoryTableRow {
    switch (query.orderColumn) {
      case LessonReviewHistoryOrderColumn.Date:
        return 'date';
      case LessonReviewHistoryOrderColumn.LessonType:
        return 'lessonType';
      default:
        return 'date';
    }
  }

  const renderViewButton = (lessonReviewId?: string, lessonNoteId?: string, submittedById?: string | null) => {
    if (studentProfileId && lessonReviewId) {
      return (
        <Link to={routesDictionary.myStudents.details.reviews.lessonReviewId.url(studentProfileId, lessonReviewId)}>
          <Button variant="outlined" size="small">
            View
          </Button>
        </Link>
      );
    }

    if (lessonNoteId && submittedById === teacherAccountId) {
      return (
        <Button onClick={() => handleViewClick(lessonNoteId)} variant="outlined" size="small">
          View
        </Button>
      );
    }
  };

  const headers: HeaderData<ReviewHistoryTableRow>[] = [
    { name: 'Date', property: 'date', isSortable: true },
    { name: 'Lesson Type', property: 'lessonType', isSortable: true },
    { name: 'Focus', property: 'focuses', isSortable: false, isHidden: sub600px },
    { name: 'View Details', property: 'viewButton', isSortable: false },
  ];

  const rowData =
    reviewData?.data?.data.map<ReviewHistoryTableRow>((row) => ({
      date: DateTime.fromISO(row.date).toFormat(DateFormats.FULL_DATE),
      lessonType: getLessonReviewLessonTypeLabel(row.lessonType),
      focuses: row.focuses ? row.focuses : null,
      viewButton: renderViewButton(row.lessonReviewId, row.lessonNoteId, row?.submittedById),
    })) ?? [];

  const count = reviewData.data?.count ?? 0;

  return (
    <>
      <Card title="Lesson Review History" isLoading={reviewData.isFetching}>
        <TableV2
          data={rowData}
          headers={headers}
          isPaginated
          isSortable
          count={count}
          page={query.page - 1}
          sortOrder={query.orderBy}
          sortBy={sortBy()}
          onPageChange={handleChangePage}
          onSortBy={handleSortBy}
          onRowsPerPageChange={handleChangeRowsPerPage}
          emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
        />
      </Card>
      {showDialog && (
        // Conditionally rendering this Dialog to stop the fetch on component render
        <LessonNotesDialog showDialog={showDialog} setShowDialog={setShowDialog} reviewId={reviewId} studentProfileId={studentProfileId} />
      )}
    </>
  );
};

export default LessonReviewHistory;
