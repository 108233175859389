import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Dialog, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import { useGetReadyV2Dialog } from '@hoot/ui/context/GetReadyV2DialogProvider';
import { useStudentJoinLessonCtx } from '@hoot/ui/context/StudentJoinLessonContext';
import { useUpcomingLessons } from '@hoot/ui/context/UpcomingLessonsProvider';
import { formatPossessiveNoun } from '@hoot/utils/text-display';
import { Button } from '../core/Button';
import HootTypography from '../core/HootTypography';

const StudentJoinLessonDialog = () => {
  const { openLesson } = useUpcomingLessons();
  const { startStudentGetReady } = useGetReadyV2Dialog();
  const { isJoinDialogOpen, handleSetJoinDialog, handleRemindMe, canDialogBeMinimized } = useStudentJoinLessonCtx();
  const navigate = useNavigate();

  const [exitAnimaton, setExitAnimation] = useState<boolean>(false);

  const dialogRef = useRef<HTMLDivElement>(null);

  // Resets exit animation state if the Join Dialog state is true.
  useEffect(() => {
    if (isJoinDialogOpen) {
      setExitAnimation(false);
    }
  }, [isJoinDialogOpen]);

  const onJoinLessonClicked = () => {
    handleClose();
    startStudentGetReady(openLesson ? { lessonId: openLesson?.lessonId, lessonNumber: openLesson?.lessonNumber } : undefined);
  };

  const onRemindMeClick = () => {
    setExitAnimation(true);
    handleRemindMe();
  };

  const onSelectProfileClick = () => {
    navigate(routesDictionary.selectProfile.url);
  };

  const handleClose = () => handleSetJoinDialog(false);

  const renderStudentName = () => {
    if (!openLesson) {
      return;
    }
    let fullName = openLesson?.studentName;
    return formatPossessiveNoun(fullName);
  };

  const RemindMeButton = () => (
    <Button onClick={onRemindMeClick} variant="contained" color="neutral.190" disableElevation fullWidth>
      Remind Me Later
    </Button>
  );

  const SelectProfileButton = () => (
    <Button onClick={onSelectProfileClick} variant="contained" color="neutral.190" disableElevation fullWidth>
      Return to Profile Selection
    </Button>
  );

  const DialogContent = () => (
    <Stack direction="column" alignItems="center" padding={3}>
      <HootTypography isPII variant="displaysmall" textAlign="center">
        {renderStudentName()}
      </HootTypography>
      <HootTypography isPII={false} variant="displaysmall" textAlign="center">
        Lesson is about to begin!
      </HootTypography>
      <img src="/images/book-opened.png" alt="logo" height="260px" width="260px" />

      <Stack rowGap={2} width="100%" justifyContent="center">
        <Button
          id={`student-join-lesson-${openLesson?.lessonNumber}`}
          onClick={onJoinLessonClicked}
          variant="contained"
          color="success.80"
          fullWidth
          sx={{
            height: '120px',
            color: '#FFF',
          }}
        >
          <Stack alignItems="center" gap={1}>
            <Lottie
              lottieFile={LottieFile.Rocket}
              loop={true}
              play={true}
              style={{
                width: '64px',
                height: '64px',
              }}
            />
            <HootTypography variant="titlelarge" isPII={true}>
              Join Lesson{openLesson?.type === ScheduledLessonType.Interview ? ` with ${openLesson?.teacherFirstName}` : ''}
            </HootTypography>
          </Stack>
        </Button>

        {!canDialogBeMinimized ? <SelectProfileButton /> : <RemindMeButton />}
      </Stack>
    </Stack>
  );

  return (
    <Dialog
      open={isJoinDialogOpen}
      ref={dialogRef}
      transitionDuration={{
        enter: 350,
        exit: 800,
      }}
      sx={{
        '& .MuiPaper-root ': {
          maxWidth: '660px',
          animation: exitAnimaton ? '800ms fadeAndSlide' : 'unset',
        },
        '@keyframes fadeAndSlide': {
          '0%': { opacity: 1 },
          '100%': { transform: 'translate(200%, -100%) scale(0.1)', opacity: 0, display: 'none' },
        },
      }}
    >
      <DialogContent />
    </Dialog>
  );
};

export default StudentJoinLessonDialog;
