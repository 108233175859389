import { FormControlLabel, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, SxProps, Theme } from '@mui/material';
import React from 'react';
import { hootTokens } from '../../../theme/v2/tokens';
import HootTypography from './HootTypography';

export type CheckboxProps = MuiCheckboxProps & {
  error?: boolean;
  label?: React.ReactNode;
};

function applyStyles(props: CheckboxProps): SxProps<Theme> {
  const iconStyles: SxProps<Theme> = {
    color: hootTokens.palette.primary[0],
  };

  const activeStyle: SxProps<Theme> = {
    backgroundColor: hootTokens.palette.primary[180],
  };

  const hoverStyle: SxProps<Theme> = {
    backgroundColor: hootTokens.palette.primary[180],
  };

  const focusStyle: SxProps<Theme> = {
    backgroundColor: hootTokens.palette.primary[190],
  };

  const styles: SxProps<Theme> = {
    '& .MuiSvgIcon-root': iconStyles,
    ':hover': hoverStyle,
    ':focus': focusStyle,
    ':active': activeStyle,
  };

  if (props.error) {
    iconStyles.color = hootTokens.palette.error[80];
    activeStyle.backgroundColor = hootTokens.palette.error[180];
    hoverStyle.backgroundColor = hootTokens.palette.error[180];
    focusStyle.backgroundColor = hootTokens.palette.error[190];
  }

  return styles;
}

export function Checkbox(props: CheckboxProps) {
  const { ...rest } = props;
  const styles = applyStyles(props);

  if (props.label) {
    return (
      <FormControlLabel
        sx={{
          '&.Mui-disabled span': {
            color: hootTokens.palette.neutral[140],
          },
          margin: 0,
        }}
        label={
          <HootTypography
            isPII={false}
            sx={{
              display: 'flex',
              alignContent: 'center',
              marginLeft: '8px',
              color: props.error ? hootTokens.palette.error[80] : hootTokens.palette.black,
            }}
            variant="labelmedium"
          >
            {props.label}
          </HootTypography>
        }
        control={<MuiCheckbox sx={styles} {...rest} />}
      />
    );
  } else {
    return <MuiCheckbox sx={styles} {...rest} />;
  }
}
