import { Grid, Grid2, Skeleton, Stack } from '@mui/material';
import useGetSchoolReportingMetrics, {
  SchoolReportingFilters,
  SchoolReportingMetricResponse,
} from '@hoot/hooks/api/district-rep/reporting/useGetSchoolReportingMetrics';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getFormattedMetricLabel, getFormattedMetricValue } from './common';

interface MetricProps {
  schoolId: string;
  filters: SchoolReportingFilters;
}

const SchoolMetrics = (props: MetricProps) => {
  const { schoolId, filters: query } = props;

  const { data, isFetching } = useGetSchoolReportingMetrics(schoolId, query);

  return (
    <div>
      <HootTypography variant="titlelarge" mb={1} isPII={false}>
        Metrics
      </HootTypography>

      {isFetching ? (
        <SkeletonGrid />
      ) : (
        <Grid2 container spacing={3}>
          {data &&
            Object.entries(data).map(([label, value]) => (
              <Grid2
                key={label}
                size={{ xs: 4 }}
                sx={{
                  padding: 3,
                  border: '1px solid #000',
                  borderRadius: '8px',
                  backgroundColor: hootTokens.palette.success[180],
                }}
              >
                <Stack alignItems="center">
                  <HootTypography variant="bodylarge" textAlign="center" isPII={false}>
                    {getFormattedMetricLabel(label as keyof SchoolReportingMetricResponse)}
                  </HootTypography>
                  <HootTypography variant="headlinelarge" isPII={false}>
                    {getFormattedMetricValue(value, label as keyof SchoolReportingMetricResponse)}
                  </HootTypography>
                </Stack>
              </Grid2>
            ))}
        </Grid2>
      )}
    </div>
  );
};

const SkeletonGrid = (): JSX.Element => {
  return (
    <Grid container spacing={3}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Grid item xs={4} key={index}>
          <Skeleton variant="rectangular" height={122} sx={{ borderRadius: '8px' }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SchoolMetrics;
