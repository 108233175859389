import { Navigate, Route, Routes } from 'react-router-dom';
import { DistrictRepresentativeEnrolmentSearchType } from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolments';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, DistrictRepProtectedRoute } from '@hoot/routes/secure/DistrictRepProtectedRoute';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import Logout from '@hoot/ui/components/v2/Logout';
import * as v2 from '@hoot/ui/components/v2/layout/AppBarLayout';
import { ProvidersLayoutV2 } from '@hoot/ui/components/v2/layout/ProvidersLayoutV2';
import PublicApplicationLayout from '@hoot/ui/components/v2/layout/PublicApplicationLayout';
import { DistrictRepDashboard } from '@hoot/ui/pages/v2/district-rep/dashboard/DistrictRepDashboard';
import HFSEnrolments from '@hoot/ui/pages/v2/district-rep/enrolments/HFSEnrolments';
import HFSEnrolmentsHeader from '@hoot/ui/pages/v2/district-rep/enrolments/HFSEnrolmentsHeader';
import DistrictRepEnrolmentsPage from '@hoot/ui/pages/v2/district-rep/enrolments/HFSEnrolmentsPage';
import DistrictRepEnrolmentDetailsPage from '@hoot/ui/pages/v2/district-rep/enrolments/enrolment/details/EnrolmentDetailsPage';
import DistrictRepProductPage from '@hoot/ui/pages/v2/district-rep/enrolments/product/HFSProductPage';
import HFSProductsHeader from '@hoot/ui/pages/v2/district-rep/enrolments/product/HFSProductsHeader';
import Reporting from '@hoot/ui/pages/v2/district-rep/reporting/Reporting';
import { DistrictRepSchedule } from '@hoot/ui/pages/v2/district-rep/schedule/DistrictRepSchedule';
import { DistrictRepScheduleDayDetails } from '@hoot/ui/pages/v2/district-rep/schedule/DistrictRepScheduleDayDetails';
import { HFSSchools } from '@hoot/ui/pages/v2/district-rep/schools/HFSSchools';
import HFSSchoolsHeader from '@hoot/ui/pages/v2/district-rep/schools/HFSSchoolsHeader';
import SchoolReportingTab from '@hoot/ui/pages/v2/district-rep/schools/reporting/SchoolReportingTab';
import { DistrictRepSchoolStudents } from '@hoot/ui/pages/v2/district-rep/schools/students/DistrictRepSchoolStudents';
import { DistrictRepSettings } from '@hoot/ui/pages/v2/district-rep/settings/DistrictRepSettings';
import { DistrictRepEnrolmentStudentsPage } from '@hoot/ui/pages/v2/district-rep/students/DistrictRepEnrolmentStudentsPage';
import DistrictRepStudentReportingPage from '@hoot/ui/pages/v2/district-rep/students/DistrictRepStudentReportingPage';
import { DistrictRepStudentsPage } from '@hoot/ui/pages/v2/district-rep/students/DistrictRepStudentsPage';
import HFSStudentHeader from '@hoot/ui/pages/v2/district-rep/students/HFSStudentHeader';
import StudentDetailsPage from '@hoot/ui/pages/v2/district-rep/students/details/StudentDetailsPage';
import DistrictRepStudentSchedule from '@hoot/ui/pages/v2/district-rep/students/schedule/DistrictRepStudentSchedule';
import HFSTeamMembers from '@hoot/ui/pages/v2/district-rep/team-members/HFSTeamMembers';
import TeamMemberDetails from '@hoot/ui/pages/v2/district-rep/team-members/details/TeamMemberDetails';
import ChangePasswordV2 from '@hoot/ui/pages/v2/public/change-password/ChangePasswordV2';
import PublicStudentSchedule from '@hoot/ui/pages/v2/public/public-student-schedule/PublicStudentSchedule';

const DistrictRepsRoutes = () => {
  return (
    <Routes>
      <Route element={<ProvidersLayoutV2 />}>
        <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
        <Route element={<PublicApplicationLayout />}>
          <Route path={routesDictionary.publicStudentSchedule.path}>
            <Route path={routesDictionary.publicStudentSchedule.location.path} element={<PublicStudentSchedule />} />
          </Route>
        </Route>
        <Route element={<v2.AppBarLayout />}>
          <Route index element={<Navigate replace to={routesDictionary.dashboard.url} />} />
          <Route path={routesDictionary.changePassword.path} element={<ChangePasswordV2 />} />
          <Route path={routesDictionary.dashboard.path} element={<DistrictRepDashboard />} />
          <Route path={routesDictionary.schedule.path}>
            <Route index element={<DistrictRepSchedule />} />
            <Route path={routesDictionary.schedule.details.path} element={<DistrictRepScheduleDayDetails />} />
          </Route>
          <Route element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.EnrolmentViewLevel} />}>
            <Route path={routesDictionary.students.path}>
              <Route index element={<DistrictRepStudentsPage />} />
              <Route path={routesDictionary.students.student.path}>
                <Route index element={<Navigate to={routesDictionary.students.student.details.path} />} />
                <Route element={<HFSStudentHeader />}>
                  <Route path={routesDictionary.students.student.details.path} element={<StudentDetailsPage />} />
                  <Route path={routesDictionary.students.student.schedule.path} element={<DistrictRepStudentSchedule />} />
                  <Route
                    element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.EnrolmentEditLevel} />}
                  >
                    <Route path={routesDictionary.students.student.reporting.path} element={<DistrictRepStudentReportingPage />} />
                  </Route>
                  <Route
                    path={routesDictionary.students.student.enrolments.path}
                    element={<DistrictRepEnrolmentsPage searchType={DistrictRepresentativeEnrolmentSearchType.Student} />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.SchoolOrDistrict} />}>
            <Route path={routesDictionary.schools.path}>
              <Route index element={<HFSSchools />} />
              <Route path={routesDictionary.schools.school.path}>
                <Route index element={<Navigate to={routesDictionary.schools.school.students.path} />} />
                <Route element={<HFSSchoolsHeader />}>
                  <Route path={routesDictionary.schools.school.students.path} element={<DistrictRepSchoolStudents />} />
                  <Route
                    path={routesDictionary.schools.school.enrolments.path}
                    element={<HFSEnrolments searchType={DistrictRepresentativeEnrolmentSearchType.School} />}
                  />
                  <Route path={routesDictionary.schools.school.reporting.path} element={<SchoolReportingTab />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.EnrolmentEditLevel} />}>
            <Route path={routesDictionary.reporting.path} element={<Reporting />} />
          </Route>

          <Route element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.SchoolOrDistrict} />}>
            <Route path={routesDictionary.teamMembers.path}>
              <Route index element={<HFSTeamMembers />} />
              <Route path={routesDictionary.teamMembers.districtRep.path}>
                <Route index element={<Navigate to={routesDictionary.teamMembers.districtRep.details.path} />} />
                <Route path={routesDictionary.teamMembers.districtRep.details.path} element={<TeamMemberDetails />} />
              </Route>
            </Route>
          </Route>

          <Route element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.EnrolmentViewLevel} />}>
            <Route path={routesDictionary.enrolments.path}>
              <Route index element={<DistrictRepEnrolmentsPage searchType={DistrictRepresentativeEnrolmentSearchType.Enrolment} />} />
              <Route path={routesDictionary.enrolments.enrolment.path}>
                <Route index element={<Navigate to={routesDictionary.enrolments.enrolment.details.path} />} />
                <Route element={<HFSEnrolmentsHeader />}>
                  <Route path={routesDictionary.enrolments.enrolment.details.path} element={<DistrictRepEnrolmentDetailsPage />} />
                  <Route path={routesDictionary.enrolments.enrolment.students.path} element={<DistrictRepEnrolmentStudentsPage />} />
                </Route>
              </Route>

              <Route element={<DistrictRepProtectedRoute requiredPermissionCombination={DistrictRepAccessLevelCombinations.BillableEntity} />}>
                <Route path={routesDictionary.enrolments.product.path}>
                  <Route index element={<Navigate to={routesDictionary.enrolments.product.details.path} />} />
                  <Route element={<HFSProductsHeader />}>
                    <Route
                      path={routesDictionary.enrolments.product.details.path}
                      element={<DistrictRepProductPage searchType={DistrictRepresentativeEnrolmentSearchType.Product} />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path={routesDictionary.settings.path} element={<DistrictRepSettings />} />
          <Route path={routesDictionary.logout.path} element={<Logout />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default DistrictRepsRoutes;
