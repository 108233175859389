import { FocusVisibilityOption } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Menu, MenuItem } from '@mui/material';
import { StudentTimelineRequest } from '@hoot/hooks/api/my-student/getStudentTimeline';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { focusVisibilityLabels } from './common';

export type FocusOptionType = {
  [key in FocusVisibilityOption]: boolean;
};

interface FocusVisibilityMenuProps {
  anchorElement: HTMLButtonElement | null;
  onDismiss: () => void;
  focusOptions: FocusOptionType;
  setFocusOptions: React.Dispatch<React.SetStateAction<FocusOptionType>>;
  setQuery: React.Dispatch<React.SetStateAction<StudentTimelineRequest>>;
}

const FocusVisibilityMenu = (props: FocusVisibilityMenuProps) => {
  const handleToggle = (option: FocusVisibilityOption) => {
    const currentFocusOptions = props.focusOptions;
    const updatedFocusOptions = { ...currentFocusOptions, [option]: !currentFocusOptions[option] };
    props.setFocusOptions(updatedFocusOptions);

    const focusVisibilityOptions = Object.keys(updatedFocusOptions).filter(
      (key) => updatedFocusOptions[key as FocusVisibilityOption] === true,
    ) as FocusVisibilityOption[];
    props.setQuery((query) => ({
      ...query,
      focusVisibility: focusVisibilityOptions,
    }));
  };

  return (
    <Menu id="focus-visibility-menu" open={!!props.anchorElement} anchorEl={props.anchorElement} onClose={props.onDismiss}>
      {Object.values(FocusVisibilityOption).map((option) => (
        <MenuItem key={option}>
          <Checkbox label={focusVisibilityLabels[option]} checked={props.focusOptions[option]} onChange={() => handleToggle(option)} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FocusVisibilityMenu;
