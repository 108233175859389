import { PMAFormVariant, PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { QueryKey } from '../../../../../../hooks/api/queryKeys';
import { RootState } from '../../../../../../redux/store';
import {
  UNIT_1_1_FORM,
  UNIT_1_2_FORM,
  UNIT_1_3_FORM,
  UNIT_1_4_FORM,
  UNIT_1_5_FORM,
  UNIT_2_1_FORM,
  UNIT_2_2_FORM,
  UNIT_2_3_FORM,
  UNIT_2_4_FORM,
  UNIT_2_5_FORM,
  UNIT_2_6_FORM,
  UNIT_3_1_FORM,
  UNIT_3_2_FORM,
  UNIT_3_3_FORM,
  UNIT_3_4_FORM,
  UNIT_3_5_FORM,
  UNIT_3_6_FORM,
  UNIT_4_2_QUESTIONNAIRE,
  UNIT_4_3_QUESTIONNAIRE,
  UNIT_4_4_QUESTIONNAIRE,
} from './enum';
import { PMA_QUESTIONNAIRE_LAYOUT_1 } from './units/PMA_QUESTIONNAIRE_LAYOUT_1';
import PMA_QUESTIONNAIRE_LAYOUT_2 from './units/PMA_QUESTIONNAIRE_LAYOUT_2';
import PMA_QUESTIONNAIRE_LAYOUT_3 from './units/PMA_QUESTIONNAIRE_LAYOUT_3';
import { PMA_QUESTIONNAIRE_LAYOUT_4 } from './units/PMA_QUESTIONNAIRE_LAYOUT_4';
import { PMA_QUESTIONNAIRE_LAYOUT_5 } from './units/PMA_QUESTIONNAIRE_LAYOUT_5';
import { PMA_QUESTIONNAIRE_LAYOUT_6 } from './units/PMA_QUESTIONNAIRE_LAYOUT_6';

function PMAPage() {
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const formVariant = useSelector((state: RootState) => state.pma.formVariant);

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(QueryKey.SearchStudentTeacherPMAs);
    };
  }, [queryClient]);

  return (
    <Stack flex={1} sx={{ p: 3, overflowY: 'auto' }}>
      <PmaUnitComponent currentUnit={currentUnit} formVariant={formVariant} />
    </Stack>
  );
}

const PmaUnitComponent = (props: { currentUnit: PMAUnitIdentifier; formVariant: PMAFormVariant }) => {
  const { currentUnit, formVariant } = props;

  switch (currentUnit) {
    case PMAUnitIdentifier.Module_1_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_1_1_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_1_2_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_1_3_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_1 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_1_4_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_1_Unit_5:
      return <PMA_QUESTIONNAIRE_LAYOUT_2 key={currentUnit} unitIdentifier={currentUnit} letterOptions={UNIT_1_5_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_2_1_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_2_2_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_2_3_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_2_4_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_5:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_2_5_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_2_Unit_6:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_2_6_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_3_1_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_3_2_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_3_3_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_3_4_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_5:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_3_5_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_3_Unit_6:
      return <PMA_QUESTIONNAIRE_LAYOUT_3 key={currentUnit} unitIdentifier={currentUnit} prompts={UNIT_3_6_FORM[formVariant]!} />;
    case PMAUnitIdentifier.Module_4_Unit_1:
      return <PMA_QUESTIONNAIRE_LAYOUT_4 key={currentUnit} unitIdentifier={currentUnit} />;
    case PMAUnitIdentifier.Module_4_Unit_2:
      return <PMA_QUESTIONNAIRE_LAYOUT_5 key={currentUnit} unitIdentifier={currentUnit} questionnaire={UNIT_4_2_QUESTIONNAIRE} />;
    case PMAUnitIdentifier.Module_4_Unit_3:
      return <PMA_QUESTIONNAIRE_LAYOUT_6 key={currentUnit} unitIdentifier={currentUnit} questionnaire={UNIT_4_3_QUESTIONNAIRE} />;
    case PMAUnitIdentifier.Module_4_Unit_4:
      return <PMA_QUESTIONNAIRE_LAYOUT_6 key={currentUnit} unitIdentifier={currentUnit} questionnaire={UNIT_4_4_QUESTIONNAIRE} />;
  }
};

export default PMAPage;
