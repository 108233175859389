import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { sentenceCase } from 'change-case';
import { InstructionalFocusModule } from '@hoot/hooks/api/assessment/useGetStudentInstructionalFocusAreasReport';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Props {
  instructionalFocusModule?: InstructionalFocusModule[];
  title: string;
  completed?: boolean;
  isAllNotStarted?: boolean;
}

const StudentCompletedOrNotStartedModules = (props: Props) => {
  const { instructionalFocusModule, title, completed, isAllNotStarted } = props;
  if (!instructionalFocusModule || instructionalFocusModule.length === 0) return null;

  return (
    <Card isLoading={false}>
      <Stack gap={2}>
        <HootTypography isPII={true} variant="titlemedium">
          {title}
        </HootTypography>
        {isAllNotStarted ? (
          <Stack direction="row" justifyContent="center" style={{ backgroundColor: hootTokens.palette.primary['195'] }}>
            <ViewStateIllustration illustration={IllustrationEnum.EmptyState} />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            {instructionalFocusModule.map((module) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }} key={module.order}>
                <Card sx={{ backgroundColor: hootTokens.palette.primary[195] }} key={module.order}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack justifyContent="center">
                      <HootTypography isPII={false} variant="titlesmall">
                        {module.label}
                      </HootTypography>
                    </Stack>
                    <Box>
                      <Tag label={sentenceCase(module.status)} color={completed ? TagColor.Success : TagColor.Neutral} />
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </Card>
  );
};

export default StudentCompletedOrNotStartedModules;
