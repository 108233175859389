import { Stack, SxProps, Theme } from '@mui/material';
import { Box } from '@mui/system';
import { CSSProperties } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export enum IllustrationEnum {
  Coffee = 'Coffee',
  NoResults = 'NoResults',
  EmptyState = 'EmptyState',
  SetupComplete = 'SetupComplete',
  UnitComplete = 'UnitComplete',
  EmptyCart = 'EmptyCart',
  NoCreditCards = 'NoCreditCards',
  NoMessages = 'NoMessages',
  EmptyInbox = 'EmptyInbox',
  NoInternet = 'NoInternet',
  Error = 'Error',
  NoGps = 'NoGps',
  NoDocuments = 'NoDocuments',
  NoImages = 'NoImages',
  ScreenTooSmall = 'ScreenTooSmall',
}

export interface ViewStateIllustrationProps {
  illustration: IllustrationEnum;
  // Overrides;
  title?: string;
  subtitle?: string;
  padding?: number;
  titleSx?: SxProps<Theme>;
  subtitleSx?: SxProps<Theme>;
  showBorder?: boolean;
  imageWidth?: CSSProperties['width'];
  imageHeight?: CSSProperties['height'];
  grayScale?: boolean;
}

interface Illustration {
  title: string;
  subtitle: string;
  imgSrc: string;
  imgAlt: string;
}

const defaults: Record<IllustrationEnum, Illustration> = {
  [IllustrationEnum.Coffee]: {
    title: "You're all caught up!",
    subtitle: 'Check back later',
    imgSrc: '/images/illustrations/coffee.svg',
    imgAlt: 'Coffee',
  },
  [IllustrationEnum.NoResults]: {
    title: 'No results found',
    subtitle: 'Try adjusting your search to find what you are looking for',
    imgSrc: '/images/illustrations/no-search-results.svg',
    imgAlt: 'No results',
  },
  [IllustrationEnum.EmptyState]: {
    title: 'Nothing Here Yet!',
    subtitle: "Don't worry, great things are on their way.",
    imgSrc: '/images/illustrations/no-internet.svg',
    imgAlt: 'No results',
  },
  [IllustrationEnum.SetupComplete]: {
    title: 'Setup completed',
    subtitle: 'Congratulations! You are all set up!',
    imgSrc: '/images/illustrations/check.svg',
    imgAlt: 'Setup Complete',
  },
  [IllustrationEnum.UnitComplete]: {
    title: 'Unit Complete',
    subtitle: "You've completed the unit!",
    imgSrc: '/images/illustrations/check.svg',
    imgAlt: 'Unit Complete',
  },
  [IllustrationEnum.EmptyCart]: {
    title: 'Your cart is empty',
    subtitle: 'Check back later',
    imgSrc: '/images/illustrations/empty-cart.svg',
    imgAlt: 'Empty Cart',
  },
  [IllustrationEnum.NoCreditCards]: {
    title: 'No credit cards',
    subtitle: 'Please add a credit card to your account',
    imgSrc: '/images/illustrations/no-credit-cards.svg',
    imgAlt: 'No credit cards.',
  },
  [IllustrationEnum.NoMessages]: {
    title: 'No messages',
    subtitle: 'You have no new messages',
    imgSrc: '/images/illustrations/no-messages.svg',
    imgAlt: 'No messages',
  },
  [IllustrationEnum.EmptyInbox]: {
    title: 'Your inbox is empty',
    subtitle: 'All incoming requests will be listed in this folder',
    imgSrc: '/images/illustrations/empty-inbox.svg',
    imgAlt: 'Empty inbox',
  },
  [IllustrationEnum.NoInternet]: {
    title: 'No internet connection',
    subtitle: 'Please check your internet connection',
    imgSrc: '/images/illustrations/no-internet.svg',
    imgAlt: 'No internet',
  },
  [IllustrationEnum.Error]: {
    title: 'Oops!',
    subtitle: 'Something went wrong, please try again',
    imgSrc: '/images/illustrations/error.svg',
    imgAlt: 'Error',
  },
  [IllustrationEnum.NoGps]: {
    title: 'No GPS connection',
    subtitle: 'Please check for location permission',
    imgSrc: '/images/illustrations/no-gps.svg',
    imgAlt: 'No GPS',
  },
  [IllustrationEnum.NoDocuments]: {
    title: 'No documents',
    subtitle: 'Start creating your documents',
    imgSrc: '/images/illustrations/no-documents.svg',
    imgAlt: 'No Documents',
  },
  [IllustrationEnum.NoImages]: {
    title: 'No images',
    subtitle: 'Start uploading images',
    imgSrc: '/images/illustrations/no-images.svg',
    imgAlt: 'No images',
  },
  [IllustrationEnum.ScreenTooSmall]: {
    title: 'Screen too small to view page!',
    subtitle: 'Try expanding your window width to view it.',
    imgSrc: '/images/illustrations/error.svg',
    imgAlt: 'Screen too small',
  },
};

const ViewStateIllustration = (props: ViewStateIllustrationProps) => {
  const {
    illustration,
    title: titleOverride,
    subtitle: subtitleOverride,
    titleSx,
    subtitleSx,
    showBorder = false,
    imageWidth = 250,
    imageHeight = 200,
    grayScale = false,
  } = props;
  const { title: defaultTitle, subtitle: defaultSubtitle, imgSrc, imgAlt } = defaults[illustration];
  return (
    <Stack
      alignItems="center"
      padding={props.padding ?? 4}
      sx={{ border: showBorder ? `1px ${hootTokens.palette.black} solid` : undefined, borderRadius: showBorder ? '8px' : undefined }}
    >
      <Box
        component="img"
        maxWidth="100%"
        src={imgSrc}
        alt={imgAlt}
        width={imageWidth ?? 250}
        height={imageHeight ?? 200}
        sx={{ filter: grayScale ? 'grayscale(1)' : 'unset' }}
      />
      <HootTypography isPII={true} variant="titlesmall" sx={{ color: hootTokens.palette.primary['60'], ...titleSx }}>
        {titleOverride ?? defaultTitle}
      </HootTypography>
      <HootTypography isPII={true} variant="bodysmall" sx={{ color: hootTokens.palette.primary['60'], ...subtitleSx }}>
        {subtitleOverride ?? defaultSubtitle}
      </HootTypography>
    </Stack>
  );
};

export default ViewStateIllustration;
