import { DateTime, Interval } from 'luxon';

const DATE_TIME_FORMAT = 'ccc, MMM dd';
export const TIME_FORMAT = 'h:mm a';
export const TWENTY_FOUR_HOUR_TIME_FORMAT = 'HH:mm';

/**
 * These values align with luxon DateTime 'weekday' getter
 * https://moment.github.io/luxon/api-docs/index.html#datetimeweekday
 *
 * As well as postgres EXTRACT(ISODOW FROM TIMESTAMP ...)
 * https://www.postgresql.org/docs/current/functions-datetime.html#FUNCTIONS-DATETIME-EXTRACT
 */
export enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export function timeFromUnixMillis(unixTime: number): string {
  return DateTime.fromMillis(unixTime).toLocal().toFormat(TIME_FORMAT).toLocaleLowerCase();
}

export function timeFromUnixMillisUpperCase(unixTime: number): string {
  return DateTime.fromMillis(unixTime).toLocal().toFormat(TIME_FORMAT);
}

export function dateFromUnixMillis(unixTime: number): string {
  return DateTime.fromMillis(unixTime).toLocal().toFormat(DATE_TIME_FORMAT);
}

export function formatDateFromMillis(unixTime: number, format: string): string {
  return DateTime.fromMillis(unixTime).toFormat(format);
}

export function hoursFromUnixMillis(start: number, end: number): number {
  return Interval.fromDateTimes(DateTime.fromMillis(start), DateTime.fromMillis(end)).length('hours');
}

export function timeZoneDisplay(timeZone: string): string {
  return `(${DateTime.now().setZone(timeZone).offsetNameShort}) ${timeZone}`;
}

export function formatDateFromIso(date: string, format: string): string {
  const _date = DateTime.fromISO(date);
  return _date.toFormat(format);
}

interface ChunkedTimeSlot {
  startsAt: DateTime;
  endsAt: DateTime;
}

export function mergeDateAndTime(date: DateTime, time: DateTime, ignoreSecondsAndMillis: boolean): DateTime {
  if (ignoreSecondsAndMillis) {
    return DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: time.hour,
      minute: time.minute,
      second: 0,
      millisecond: 0,
    });
  }

  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: time.hour,
    minute: time.minute,
    second: time.second,
    millisecond: time.millisecond,
  });
}

/**
 *
 * @param startsAt - start of your timespan
 * @param endsAt - end of your timespan
 * @returns An array of DateTime objects split up into each day spanning to start and end
 */
export function chunkTimeSpan(startsAt: DateTime, endsAt: DateTime): ChunkedTimeSlot[] {
  const results: ChunkedTimeSlot[] = [];
  let currentDate = startsAt;

  while (currentDate < endsAt) {
    const endOfDay = currentDate.endOf('day');
    const timeSlotEndsAt = endOfDay < endsAt ? endOfDay : endsAt;
    results.push({ startsAt: currentDate, endsAt: timeSlotEndsAt });
    currentDate = currentDate.plus({ day: 1 }).startOf('day');
  }

  return results;
}
