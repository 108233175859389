import { PeriodBasedValues, TimelineFilterType } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { Option } from '@hoot/models/formOption';
import DatePicker from '@hoot/ui/components/v2/core/DatePicker';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

const filterTypeOptions: Option[] = [
  { label: 'Date Based', value: TimelineFilterType.DateBased },
  { label: 'Period Based', value: TimelineFilterType.PeriodBased },
];

const periodBasedOptions: Option[] = [
  { label: 'Entire Current Enrolment', value: PeriodBasedValues.EntireEnrolment },
  { label: 'All Time Lesson History', value: PeriodBasedValues.AllTimeLessonHistory },
  { label: 'Most Recent HRA until next HRA', value: PeriodBasedValues.MostRecentUntilNextHRA },
  { label: 'Most Recent HRA until next HRA or PMA', value: PeriodBasedValues.MostRecentUntilNextHRAOrPMA },
];

export interface TimelineFilterFormValues {
  filterType: string;
  viewOption: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

const defaultValues: TimelineFilterFormValues = {
  filterType: TimelineFilterType.DateBased,
  viewOption: null,
  startDate: null,
  endDate: null,
};

interface TimelineFilterDialogProps {
  open: boolean;
  onDismiss: () => void;
  onApply: (data: TimelineFilterFormValues) => void;
}

const TimelineFilterDialog = (props: TimelineFilterDialogProps) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<TimelineFilterFormValues>({
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    props.onDismiss();
    clearErrors();
  };

  const handleApply = (data: TimelineFilterFormValues) => {
    props.onApply(data);
    props.onDismiss();
  };

  const isPeriodBasedSelected = watch('filterType') === TimelineFilterType.PeriodBased;

  const minStartDate = DateTime.fromObject({ year: 2023, month: 1, day: 1 }); // January 1, 2023.
  const maxEndDate = DateTime.now().plus({ years: 1 }); // 1 Year from now.

  return (
    <BasicAlertDialog
      title="Timeline Filter"
      show={props.open}
      onDismiss={handleClose}
      maxWidth={'xs'}
      content={
        <Stack gap={2}>
          {/* Filter Type */}
          <Controller
            name="filterType"
            control={control}
            render={({ field }) => (
              <div>
                <HootTypography variant="titlesmall" mb={1} isPII={false}>
                  Filter
                </HootTypography>
                <Select
                  label="Filter Type"
                  value={field.value}
                  onChange={(e) => {
                    // Clear the Date fields if we select `PeriodBased`
                    if (e.target.value === TimelineFilterType.PeriodBased) {
                      setValue('startDate', null);
                      setValue('endDate', null);
                    } else {
                      // Clear the ViewOption field if we select `DateBased`
                      setValue('viewOption', null);
                    }
                    field.onChange(e);
                  }}
                  sx={{ width: '100%' }}
                >
                  {filterTypeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </div>
            )}
          />

          {/* View Option */}
          {isPeriodBasedSelected ? (
            <Controller
              name="viewOption"
              control={control}
              rules={{
                required: 'View Option is required',
              }}
              render={({ field: { value, onChange } }) => (
                <div>
                  <HootTypography variant="titlesmall" mb={1} isPII={false}>
                    View Option
                  </HootTypography>
                  <Select
                    label="Option"
                    value={value ?? ''}
                    onChange={onChange}
                    error={!!errors.viewOption}
                    helperText={errors.viewOption?.message ?? ''}
                    sx={{ width: '100%' }}
                    required
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    {periodBasedOptions.map((option) => (
                      <option key={`option-${option.value}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
            />
          ) : (
            // Date fields
            <div>
              <HootTypography variant="titlesmall" mb={1} isPII={false}>
                View Option
              </HootTypography>
              <Stack direction="row" gap={1}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: 'Start Date is required',
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      label="From Date"
                      value={value}
                      onChange={onChange}
                      minDate={minStartDate}
                      disableFuture
                      error={!!errors.startDate}
                      helperText={errors.startDate?.message ?? ''}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: 'End Date is required',
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      label="To Date"
                      value={value}
                      onChange={onChange}
                      maxDate={maxEndDate}
                      disablePast
                      error={!!errors.endDate}
                      helperText={errors.endDate?.message ?? ''}
                    />
                  )}
                />
              </Stack>
            </div>
          )}
        </Stack>
      }
      primaryAction={{
        label: 'Apply',
        onClick: handleSubmit(handleApply),
        props: {
          variant: 'contained',
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: handleClose,
      }}
    />
  );
};

export default TimelineFilterDialog;
