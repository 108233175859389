import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';
import { BookType, DecodableFormat } from '@hoot/models/api/library';

export interface QueryInstructionalUnitResourcesDto {
  omitSampleWordsAndTasks?: boolean;
  omitResources?: boolean;
  resourceType?: BookType;
  excludedResourceTypes?: BookType[];
}

export interface PrioritizedSkillAndResourcesResponse {
  prioritizedSkillId: string;
  prioritizedSkillName: string;
  sampleTasksAndWords: string[];
  books: {
    id: string;
    title: string;
    decodableFormatIdEnum: DecodableFormat | null;
  }[];
}

export default function useGetPrioritizedSkillsAndResourcesForInstructionalUnit(
  instructionalUnitId: string,
  query: QueryInstructionalUnitResourcesDto,
  options?: UseGetOptions<PrioritizedSkillAndResourcesResponse[]>,
) {
  return useQuery(
    [QueryKey.GetInstructionalUnitDetails, instructionalUnitId],
    async () => {
      return (
        await axios.get<PrioritizedSkillAndResourcesResponse[]>(
          `${config.apiUrl}/library/instructional-units/${instructionalUnitId}/skills-and-resources`,
          { params: query },
        )
      ).data;
    },
    options,
  );
}
