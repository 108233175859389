import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { QuestionnaireLayout1 } from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout1';
import { Unit1Module1Example, Unit1Module2Example, Unit1Module3Example, Unit1Module4Example } from '../UnitExample';
import { pmaInstructions, pmaTitles } from '../enum';
import useTeacherPMAController from '../useTeacherPMAController';

interface Props {
  unitIdentifier:
    | PMAUnitIdentifier.Module_1_Unit_1
    | PMAUnitIdentifier.Module_1_Unit_2
    | PMAUnitIdentifier.Module_1_Unit_3
    | PMAUnitIdentifier.Module_1_Unit_4;
  prompts: PMAQuestionnaireLayout_1_Prompts[];
}

export interface PMAQuestionnaireLayout_1_Prompts {
  sentence: string;
  word: string;
  expectedResponse: string;
  wordsToBold: string;
}

export function PMA_QUESTIONNAIRE_LAYOUT_1(props: Props) {
  const { unitIdentifier } = props;

  const [hasUnitBeenSubmitted, setHasUnitBeenSubmitted] = useState(false);
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);

  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);
  const [unitQuestionAnswerPair, setUnitQuestionAnswerPair] = useState<UnitQuestionAnswerPair[]>(
    props.prompts.map<UnitQuestionAnswerPair>((p) => ({
      fullPrompt: p.sentence,
      wordPrompt: p.word,
      answer: PMAStepTestAnswer.NoAnswer,
      expectedAnswer: p.expectedResponse,
    })),
  );
  const dispatch = useAppDispatch();
  const pmaController = useTeacherPMAController();

  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);

  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, unitIdentifier);

  useSearchPMAUnitSubmissionData(assessmentID!, unitIdentifier, {
    enabled: !!assessmentID,
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (!!data.submissionData) {
        setHasUnitBeenSubmitted(true);
        setUnitQuestionAnswerPair(data.submissionData as UnitQuestionAnswerPair[]);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);

  const handleOnChange = (val: UnitQuestionAnswerPair[]) => {
    setUnitQuestionAnswerPair(val);
  };

  const handleAllQuestionsAnswered = () => {
    pmaController.onUnitComplete(true);
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: unitQuestionAnswerPair,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  return (
    <QuestionnaireLayout1
      value={unitQuestionAnswerPair}
      unitIdentifier={unitIdentifier}
      onChange={handleOnChange}
      title="Progress Monitoring Assessment"
      instruction={pmaInstructions[unitIdentifier]}
      additionalInstruction={<ShowExample unitIdentifier={unitIdentifier} />}
      unitTitle={pmaTitles[unitIdentifier]}
      onPreviousUnitClick={onPreviousUnitClick}
      onSubmit={onNextUnitAndSubmissionClick}
      isNextEnabled={isNextEnabled}
      isPrevEnabled={isPrevEnabled}
      confirmPrevUnitClick={confirmPrevUnitClick}
      prompts={props.prompts}
      isUnitComplete={isUnitComplete}
      isLoading={submitPMAUnitRequest.isLoading}
      isSubmitting={submitPMAUnitRequest.isLoading}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onAllQuestionsAnswered={handleAllQuestionsAnswered}
      hasUnitBeenSubmitted={hasUnitBeenSubmitted}
      onExitAssessment={pmaController.onExitAssessment}
    />
  );
}

const ShowExample = (props: { unitIdentifier: PMAUnitIdentifier }) => {
  const { unitIdentifier } = props;
  if (unitIdentifier === PMAUnitIdentifier.Module_1_Unit_1) {
    return <Unit1Module1Example />;
  } else if (unitIdentifier === PMAUnitIdentifier.Module_1_Unit_2) {
    return <Unit1Module2Example />;
  } else if (unitIdentifier === PMAUnitIdentifier.Module_1_Unit_3) {
    return <Unit1Module3Example />;
  } else if (unitIdentifier === PMAUnitIdentifier.Module_1_Unit_4) {
    return <Unit1Module4Example />;
  }
  return null;
};
