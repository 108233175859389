import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import QuestionnaireLayout2 from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout2';
import { pmaInstructions, pmaTitles } from '../enum';
import useTeacherPMAController from '../useTeacherPMAController';

/** INTERFACES */

interface Props {
  unitIdentifier: PMAUnitIdentifier.Module_1_Unit_5;
  letterOptions: string[];
}

/** StepTest */
const PMA_QUESTIONNAIRE_LAYOUT_2 = (props: Props) => {
  const { unitIdentifier } = props;
  const [hasUnitBeenSubmitted, setHasUnitBeenSubmitted] = useState(false);
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);

  const [unitQuestionAnswerPair, setUnitQuestionAnswerPair] = useState<UnitQuestionAnswerPair[]>(
    props.letterOptions.map<UnitQuestionAnswerPair>((lo) => ({
      fullPrompt: lo,
      wordPrompt: lo,
      answer: PMAStepTestAnswer.NoAnswer,
    })),
  );

  const dispatch = useAppDispatch();
  const pmaController = useTeacherPMAController();

  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);

  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, unitIdentifier);

  useSearchPMAUnitSubmissionData(assessmentID!, unitIdentifier, {
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (data.submissionData) {
        setHasUnitBeenSubmitted(true);
        setUnitQuestionAnswerPair(data.submissionData as UnitQuestionAnswerPair[]);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const handleOnChange = (val: UnitQuestionAnswerPair[]) => {
    setUnitQuestionAnswerPair(val);
  };

  const handleAllQuestionsAnswered = () => {
    pmaController.onUnitComplete(true);
  };

  const handleSetText = (text: string) => {
    pmaController.onSetText(text);
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: unitQuestionAnswerPair,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  return (
    <QuestionnaireLayout2
      unitIdentifier={unitIdentifier}
      value={unitQuestionAnswerPair}
      onChange={handleOnChange}
      title="Progress Monitoring Assessment"
      instruction={pmaInstructions[unitIdentifier]}
      unitTitle={pmaTitles[unitIdentifier]}
      onPreviousUnitClick={onPreviousUnitClick}
      onSubmit={onNextUnitAndSubmissionClick}
      isNextEnabled={isNextEnabled}
      isPrevEnabled={isPrevEnabled}
      confirmPrevUnitClick={confirmPrevUnitClick}
      isSubmitting={submitPMAUnitRequest.isLoading}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onAllQuestionsAnswered={handleAllQuestionsAnswered}
      allowedValues={props.letterOptions}
      onSetText={handleSetText}
      hasUnitBeenSubmitted={hasUnitBeenSubmitted}
      onExitAssessment={pmaController.onExitAssessment}
      isDirty={isUnitQuestionnaireDirty}
      setIsDirty={setIsUnitQuestionnaireDirty}
    />
  );
};

export default PMA_QUESTIONNAIRE_LAYOUT_2;
