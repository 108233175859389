import { SvgIcon, SvgIconProps } from '@mui/material';

const SchoolBellIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        opacity="0.4"
        d="M17.4997 3.33268C25.3237 3.33268 31.6663 9.67531 31.6663 17.4993C31.6663 25.3234 25.3237 31.666 17.4997 31.666C9.67564 31.666 3.33301 25.3234 3.33301 17.4993C3.33301 9.67531 9.67564 3.33268 17.4997 3.33268Z"
        fill="#EF4823"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.25 31.6122V36.6673C16.25 37.3577 16.8097 37.9173 17.5 37.9173C28.6618 37.9173 37.7317 28.9603 37.9138 17.842C37.923 17.7295 37.9277 17.6157 37.9277 17.5007C37.9277 15.1995 36.0622 13.334 33.761 13.334C31.4598 13.334 29.5943 15.1995 29.5943 17.5007C29.5943 19.8018 31.4598 21.6673 33.761 21.6673C34.1818 21.6673 34.5882 21.605 34.9712 21.4888C33.2475 29.0712 26.7147 34.8255 18.75 35.3743V31.6122C17.915 31.6862 17.0785 31.6855 16.25 31.6122Z"
        fill="#EF4823"
      />
      <path
        d="M20.8334 18.3333C20.8334 16.4924 19.3411 15 17.5001 15C15.6591 15 14.1667 16.4924 14.1667 18.3333C14.1667 20.1743 15.6591 21.6667 17.5001 21.6667C19.3411 21.6667 20.8334 20.1743 20.8334 18.3333Z"
        fill="#EF4823"
      />
    </SvgIcon>
  );
};

export default SchoolBellIcon;
