import { Box, Fade } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { ScopeAndSequenceFocusArea } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const splashImageTransitionTimeoutMs = 500;

interface ScopeAndSequenceFocusAreaBannerProps {
  focusArea: ScopeAndSequenceFocusArea;
}

const ScopeAndSequenceFocusAreaBanner = (props: ScopeAndSequenceFocusAreaBannerProps) => {
  const { focusArea } = props;

  const [splashImageLoaded, setSplashImageLoaded] = useState(false);

  // Whenever the focus are has changed, set the image loaded flag to false before re-rendering the image.
  useEffect(() => {
    setSplashImageLoaded(false);
  }, [focusArea.slug, setSplashImageLoaded]);

  const onSplashImageLoaded = () => {
    setSplashImageLoaded(true);
  };

  return (
    <Box sx={{ width: '100%', height: '300px', position: 'relative' }}>
      <Fade in key={`splash-${focusArea.instructionalFocusId}`} timeout={splashImageTransitionTimeoutMs}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <AsyncImage
            key={focusArea.splashImageUrl}
            src={focusArea.splashImageUrl}
            alt={focusArea.splashImageAlt}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              objectFit: 'cover',
            }}
            onImageLoaded={onSplashImageLoaded}
          />
        </Box>
      </Fade>
      {/* Don't display text until the image is loaded. It's super hard to read while superimposed on top of the Skeleton component. */}
      {splashImageLoaded && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 3,
          }}
        >
          <Grid>
            <Grid container>
              <Grid size={{ xs: 12, md: 8 }}>
                <HootTypography isPII={false} variant="displaylarge" sx={{ color: hootTokens.palette.white }}>
                  {focusArea.title}
                </HootTypography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ScopeAndSequenceFocusAreaBanner;
