import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface InstructionalUnitResponse {
  id: string;
  name: string;
  slug: string;
}

export default function useGetInstructionalUnitsForInstructionalFocus(
  instructionalFocusId: string,
  options?: UseGetOptions<InstructionalUnitResponse[]>,
) {
  return useQuery(
    [QueryKey.GetInstructionalFocusInstructionalUnits, instructionalFocusId],
    async () => {
      return (
        await axios.get<InstructionalUnitResponse[]>(`${config.apiUrl}/library/instructional-focuses/${instructionalFocusId}/instructional-units`)
      ).data;
    },
    options,
  );
}
