import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { StudentStatusEnum } from '@hoot/models/api/student';
import { DateRangeFormValues } from '@hoot/ui/pages/v2/district-rep/schools/reporting/DateRangeDialog';
import { AttendanceFilterEnum, DateFilterEnum, LateJoinFilterEnum } from '@hoot/ui/pages/v2/district-rep/schools/reporting/common';
import { QueryKey } from '../../queryKeys';

export interface SchoolReportingFilters {
  date?: DateFilterEnum[] | DateRangeFormValues;
  studentStatus?: StudentStatusEnum[];
  studentGrade?: StudentGrade[];
  attendance?: AttendanceFilterEnum[];
  lateJoin?: LateJoinFilterEnum[];
}

export interface SchoolReportingMetricResponse {
  totalLessons: number;
  occurredLessons: number;
  totalStudents: number;
  averageAttendanceRate: number;
  lateJoinRate: number;
  minutesInLesson: number;
}

export default function useGetSchoolReportingMetrics(
  schoolId: string,
  query: SchoolReportingFilters,
  options?: Omit<UseQueryOptions<SchoolReportingMetricResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepReportingSchoolMetrics, schoolId, query],
    async () => {
      return (
        await axios.get<SchoolReportingMetricResponse>(`${config.apiUrl}/public/district-representative/reporting/schools/${schoolId}/metrics`, {
          params: query,
        })
      ).data;
    },
    options,
  );
}
