import { Stack, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import { DistrictRepDailyEnrolmentsLessonsMessageRequest } from '@hoot/events/messages/district-rep-daily-enrolments-lessons-message-request';
import useDistrictRepScheduledLessonsQuery, {
  DistrictRepLessonsQuery,
} from '@hoot/hooks/api/district-rep/useDistrictRepMonthlyEnrolmentsLessonsQuery';
import { scheduledLessonStatusSortPriority } from '@hoot/models/api/enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DistrictRepLessonDailyQuery, DistrictRepScheduledLesson } from '@hoot/models/api/lessons';
import { setRefreshDistrictRepEnrolmentsLessons, updateIsDistrictRepEnrolmentsLessonsLoading } from '@hoot/redux/reducers/districtRepSlice';
import { RootState } from '@hoot/redux/store';
import GrowList from '@hoot/ui/components/v2/GrowList';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Calendar, { PopulatedDate } from '@hoot/ui/components/v2/core/Calendar';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { useScrollToTop } from '@hoot/ui/context/ScrollToTopContext';
import { useSocket } from '@hoot/ui/context/SocketContext';
import theme from '@hoot/ui/theme/v2';
import DistrictRepEnrolmentsLessons, { DistrictRepEnrolmentsLessonsSkeleton } from '../../schedule/DistrictRepEnrolmentsLessons';

const DistrictRepStudentSchedule = () => {
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const [monthlyScheduledLessonsQuery, setMonthlyScheduledLessonsQuery] = useState<DistrictRepLessonsQuery>();
  const [selectedCalendarMonth, setSelectedCalendarMonth] = useState<number>(DateTime.now().toMillis());
  const monthlyScheduledLessons = useDistrictRepScheduledLessonsQuery(districtRepresentativeId!, monthlyScheduledLessonsQuery);
  const { studentProfileId } = useParams();
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
  const [dayLessonsQuery, setDayLessonsQuery] = useState<DistrictRepLessonDailyQuery>();
  const { isConnected } = useSelector((state: RootState) => state.application);
  const [skeletonItemCount, setSkeletonItemCount] = useState<number>(1);
  const { socket } = useSocket();
  const { districtRepEnrolmentsLessons, refreshDistrictRepEnrolmentsLessons, isDistrictRepEnrolmentsLessonsLoading } = useSelector(
    (state: RootState) => state.districtRep,
  );
  const sortedDistrictRepEnrolmentsLessons = [...districtRepEnrolmentsLessons].sort(
    (a, b) =>
      (scheduledLessonStatusSortPriority.get(a.lessonStatus) || 0) - (scheduledLessonStatusSortPriority.get(b.lessonStatus) || 0) ||
      a.lessonStartTime - b.lessonStartTime,
  );

  const dispatch = useDispatch();
  const { scrollToTop } = useScrollToTop();
  const below900px = useMediaQuery(theme.breakpoints.down('md'));

  const pageLoading = monthlyScheduledLessons.isLoading || isDistrictRepEnrolmentsLessonsLoading;

  useEffect(() => {
    setMonthlyScheduledLessonsQuery({
      startDate: DateTime.fromMillis(selectedCalendarMonth).startOf('month').toMillis(),
      endDate: DateTime.fromMillis(selectedCalendarMonth).endOf('month').toMillis(),
      studentProfileId: studentProfileId ?? '',
    });
  }, [selectedCalendarMonth, studentProfileId]);

  useEffect(() => {
    setDayLessonsQuery((prevState) => {
      const fromDate = selectedDate.startOf('day').toMillis();
      const toDate = selectedDate.endOf('day').toMillis();
      // deep compare properties that could have changed to avoid re-triggering other effects that depend on this query object
      if (prevState?.fromDate === fromDate && prevState.toDate === toDate) {
        return prevState;
      }

      const query: DistrictRepLessonDailyQuery = {
        studentProfileId: studentProfileId ?? '',
        fromDate,
        toDate,
        orderBy: OrderBy.Asc,
      };

      return query;
    });
  }, [selectedDate, studentProfileId]);

  useEffect(() => {
    if (dayLessonsQuery && isConnected) {
      const districtRepDailyEnrolmentsLessonsMessageRequest: DistrictRepDailyEnrolmentsLessonsMessageRequest = {
        query: dayLessonsQuery,
      };

      socket.emit(EventType.DistrictRepEnrolmentsLessons, districtRepDailyEnrolmentsLessonsMessageRequest);
      dispatch(updateIsDistrictRepEnrolmentsLessonsLoading(true));

      if (refreshDistrictRepEnrolmentsLessons) {
        dispatch(setRefreshDistrictRepEnrolmentsLessons(false));
      }
    }
  }, [dispatch, socket, dayLessonsQuery, isConnected, refreshDistrictRepEnrolmentsLessons]);

  useEffect(() => {
    const dates = monthlyScheduledLessons.data?.lessonDates;
    if (dates) {
      const selectedDateISO = selectedDate.toISODate();
      const numberOfLessons = dates.find((d) => d.date === selectedDateISO)?.dayDetails.lessonDetails.lessonCount;

      setSkeletonItemCount(numberOfLessons ?? 1);
    }
  }, [selectedDate, monthlyScheduledLessons.data]);

  const getPopulatedDates = () => {
    const dates = monthlyScheduledLessons.data?.lessonDates;
    let populatedDates: PopulatedDate[] = [];

    if (dates) {
      for (const date of dates) {
        const populatedDate: PopulatedDate = {
          dateVal: DateTime.fromISO(date.date),
          numberOfLessons: date.dayDetails?.lessonDetails?.lessonCount,
          statusToDisplay: date.dayDetails.statusToDisplay,
        };

        populatedDates.push(populatedDate);
      }
    }
    return populatedDates;
  };

  const selectedDateLessons = (selectedCalendarDate: number) => {
    setSelectedDate(DateTime.fromMillis(selectedCalendarDate));

    //otherwise this is annoying
    if (!below900px) {
      scrollToTop();
    }
  };

  const ContentDisplay = () => {
    if (pageLoading) {
      const skeletonListSize = Array(skeletonItemCount).fill('');
      return (
        <GrowList<string>
          StackProps={{ width: '100%', gap: 4 }}
          items={skeletonListSize}
          getKey={(_, i) => `skeleton-item-${i}`}
          renderItem={() => <DistrictRepEnrolmentsLessonsSkeleton />}
        />
      );
    } else if (sortedDistrictRepEnrolmentsLessons && sortedDistrictRepEnrolmentsLessons.length > 0 && !isDistrictRepEnrolmentsLessonsLoading) {
      return (
        <GrowList<DistrictRepScheduledLesson>
          StackProps={{ width: '100%', gap: 4 }}
          items={sortedDistrictRepEnrolmentsLessons}
          getKey={(x, i) => `${i}-${x.enrolmentFriendlyId}`}
          renderItem={(lesson) => <DistrictRepEnrolmentsLessons lesson={lesson} />}
        />
      );
    } else {
      return <ViewStateIllustration illustration={IllustrationEnum.EmptyInbox} title="All Clear!" subtitle="There are no lessons on this day" />;
    }
  };

  return (
    <Page pageTitle="District Representative Student Schedule | Hoot Reading" noMaxWidth>
      <Card sectionHeader={'Schedule'} isLoading={pageLoading} contentSx={{ p: 3, pt: 2.5 }}>
        <>
          {below900px ? (
            <Grid container gap={5} flexDirection={'column'}>
              <Grid size={12}>
                <Calendar
                  value={selectedDate.toMillis()}
                  populatedDates={getPopulatedDates()}
                  onChange={selectedDateLessons}
                  setSelectedCalendarMonth={setSelectedCalendarMonth}
                  setSelectedDate={setSelectedDate}
                />
              </Grid>
              <Grid size={12}>
                <ContentDisplay />
              </Grid>
            </Grid>
          ) : (
            <Grid container gap={2} flexWrap={'nowrap'}>
              <Grid container size={5} justifyContent={'center'}>
                <Calendar
                  value={selectedDate.toMillis()}
                  setSelectedCalendarMonth={setSelectedCalendarMonth}
                  setSelectedDate={setSelectedDate}
                  populatedDates={getPopulatedDates()}
                  onChange={selectedDateLessons}
                />
              </Grid>
              <Grid container size={6} sx={{ mr: 3 }}>
                <Stack sx={{ width: '100%' }}>
                  <HootTypography isPII={false} variant="titlemedium" sx={{ mb: 2 }}>
                    {`${selectedDate.toLocaleString(DateTime.DATE_HUGE)}`}
                  </HootTypography>
                  <ContentDisplay />
                </Stack>
              </Grid>
            </Grid>
          )}
        </>
      </Card>
    </Page>
  );
};

export default DistrictRepStudentSchedule;
