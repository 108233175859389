export enum UnitIdentifier {
  U_SDQ = 'U_SDQ',
  U_1_1A = 'U_1_1A',
  U_1_1B = 'U_1_1B',
  U_1_1C = 'U_1_1C',
  U_1_1D = 'U_1_1D',
  U_1_1E = 'U_1_1E',
  U_1_2A = 'U_1_2A',
  U_1_2B = 'U_1_2B',
  U_1_2C = 'U_1_2C',
  U_1_2D = 'U_1_2D',
  U_1_3A = 'U_1_3A',
  U_2_1A = 'U_2_1A',
  U_2_2A = 'U_2_2A',
  U_2_2B = 'U_2_2B',
  U_2_2C = 'U_2_2C',
  U_2_3A = 'U_2_3A',
  U_2_3B = 'U_2_3B',
  U_2_3C = 'U_2_3C',
  U_3_1A = 'U_3_1A',
  U_3_1B = 'U_3_1B',
  U_3_2A = 'U_3_2A',
  U_3_2B = 'U_3_2B',
  U_4_1A = 'U_4_1A',
  U_4_2A = 'U_4_2A',
  U_4_3A = 'U_4_3A',
  U_ATR = 'U_ATR',
  U_HRAC = 'U_HRAC',
}

export enum UnitIdentifierHRAV2 {
  U_1_1 = 'U_1_1',
  U_1_2 = 'U_1_2',
  U_1_3 = 'U_1_3',
  U_1_4 = 'U_1_4',
  U_1_5 = 'U_1_5',
  U_2_1 = 'U_2_1',
  U_2_2 = 'U_2_2',
  U_2_3 = 'U_2_3',
  U_2_4 = 'U_2_4',
  U_2_5 = 'U_2_5',
  U_2_6 = 'U_2_6',
  U_3_1 = 'U_3_1',
  U_3_2 = 'U_3_2',
  U_3_3 = 'U_3_3',
  U_3_4 = 'U_3_4',
  U_3_5 = 'U_3_5',
  U_3_6 = 'U_3_6',
  U_4_1 = 'U_4_1',
  U_4_2 = 'U_4_2',
  U_4_3 = 'U_4_3',
  U_4_4 = 'U_4_4',
  U_HRAV2C = 'U_HRAV2C',
}

export enum HootAssessmentUnitSet {
  SanDiegoQuick = 'SAN_DIEGO_QUICK',
  PhonologicalAwareness = 'PHONOLOGICAL_AWARENESS',
  PhonemicAwareness = 'PHONEMIC_AWARENESS',
  LetterSounds = 'LETTER_SOUNDS',
  CvcWords = 'CVC_WORDS',
  DigraphsAndBlends = 'DIGRAPHS_AND_BLENDS',
  BasicVowels = 'BASIC_VOWELS',
  AdvancedVowels = 'ADVANCED_VOWELS',
  ComplexWords = 'COMPLEX_WORDS',
  ReadingFluency = 'READING_FLUENCY',
  ComprehensionStrategies = 'COMPREHENSION_STRATEGIES',
  AttitudeTowardsReading = 'ATTITUDE_TOWARDS_READING',
}

export enum FrustrationLevel {
  PrePrimer = 'PRE_PRIMER',
  Primer = 'PRIMER',
  Grade1 = 'GRADE_1',
  Grade2 = 'GRADE_2',
  Grade3 = 'GRADE_3',
  Grade4 = 'GRADE_4',
  Grade5 = 'GRADE_5',
  Grade6 = 'GRADE_6',
  Grade7 = 'GRADE_7',
  Grade8 = 'GRADE_8',
  Grade9 = 'GRADE_9',
  Grade10 = 'GRADE_10',
  Grade11 = 'GRADE_11',
  Grade12 = 'GRADE_12',
}

export enum HootAssessmentFieldType {
  Segment = 'SEGMENT',
  StepTest = 'STEP_TEST',
  Attitude = 'ATTITUDE',
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO',
  DropdownSingle = 'DROPDOWN_SINGLE',
  DropdownMulti = 'DROPDOWN_MULTI',
  BookSelector = 'BOOK_SELECTOR',
  Number = 'NUMBER',
  TimePicker = 'TIME_PICKER',
  Calculated = 'CALCULATED',
  CalculatedPercentage = 'CALCULATED_PERCENTAGE',
}

export enum HootAssessmentInstructionIndicator {
  NeedsInstruction = 'NEEDS_INSTRUCTION',
  NeedsReview = 'NEEDS_REVIEW',
  Mastered = 'MASTERED',
}

export enum HootAssessmentStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Abandoned = 'ABANDONED',
}

export enum ConditionAction {
  Hide = 'HIDE',
}

export enum Operator {
  Add = '+',
  Subtract = '-',
  Multiply = '*',
  Divide = '/',
}

export enum OperandType {
  Number = 'NUMBER',
  Field = 'FIELD',
  Calculation = 'CALCULATION',
}

export const enum StepTestAnswer {
  Correct = 'CORRECT',
  Wrong = 'WRONG',
  NoAnswer = '',
}

export const enum SegmentAnswer {
  Correct = 'CORRECT',
  Wrong = 'WRONG',
  NoAnswer = '',
}

export enum FlexDirection {
  Row = 'row',
  Column = 'column',
}

export enum FlexJustify {
  Between = 'space-between',
  Evenly = 'space-evenly',
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  Around = 'space-around',
}
