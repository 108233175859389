import { Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useMemo } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import useGetInstructionalUnitsForInstructionalFocus, {
  InstructionalUnitResponse,
} from '@hoot/hooks/api/library/useGetInstructionalUnitsForInstructionalFocus';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import GrowList from '@hoot/ui/components/v2/GrowList';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import ScopeAndSequenceIcon from '@hoot/ui/components/v2/icons/ScopeAndSequenceIcon';
import ScopeAndSequenceFocusAreaBanner from '@hoot/ui/pages/v2/teacher/scope-and-sequence/ScopeAndSequenceFocusAreaBanner';
import {
  FocusAreaSlug,
  ScopeAndSequenceFocusArea,
  scopeAndSequenceFocusAreaDictionary,
  scopeAndSequenceTileColourDictionary,
} from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface ScopeAndSequenceFocusAreaDetailPageProps {
  focusArea: ScopeAndSequenceFocusArea;
}

const ScopeAndSequenceFocusAreaDetailPage = (props: ScopeAndSequenceFocusAreaDetailPageProps) => {
  const { focusArea } = props;

  const { unitSlug } = useParams<{ unitSlug: string }>();
  const navigate = useNavigate();

  const tileColours = scopeAndSequenceTileColourDictionary[focusArea.slug as FocusAreaSlug].tileColours;

  const getInstructionalUnitsRequest = useGetInstructionalUnitsForInstructionalFocus(focusArea.instructionalFocusId, {
    onSuccess: (response) => {
      // If no unit is selected (no unit slug exists in the path) OR if the unit slug in the path is bogus,
      // then just auto-select the first unit from the response.
      if (response.length > 0 && (!unitSlug || !response.some((x) => x.slug === unitSlug))) {
        navigate(response[0].slug, { replace: true });
      }
    },
  });

  const selectedInstructionalUnit = useMemo(() => {
    return getInstructionalUnitsRequest.data?.find((x) => x.slug === unitSlug);
  }, [getInstructionalUnitsRequest.data, unitSlug]);

  return (
    <>
      <SubHeader
        title={{
          label: focusArea.title,
          isPII: false,
        }}
        backButton={{
          show: true,
          navigateBackPath: routesDictionary.scopeAndSequence.focusArea.url(focusArea.slug),
        }}
        breadcrumbs={['Hoot Scope & Sequence', focusArea.title]}
      />
      <Page pageTitle="Scope Details | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <ScopeAndSequenceFocusAreaBanner focusArea={focusArea} />
          </Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <Card title="Select a unit" isLoading={getInstructionalUnitsRequest.isFetching}>
              {getInstructionalUnitsRequest.isFetching ? (
                <Stack gap={2}>
                  {[...Array(3)].map((_, i) => (
                    <UnitSkeletonItem key={`skeleton-${i}`} />
                  ))}
                </Stack>
              ) : (
                <GrowList
                  StackProps={{
                    gap: 2,
                  }}
                  items={getInstructionalUnitsRequest.data ?? []}
                  getKey={(item) => item.id}
                  renderItem={(item, i) => (
                    <UnitRow
                      key={item.id}
                      unit={item}
                      focusArea={focusArea}
                      isSelected={unitSlug === item.slug}
                      primaryColour={tileColours[i % tileColours.length].primary}
                      secondaryColour={tileColours[i % tileColours.length].secondary}
                    />
                  )}
                />
              )}
            </Card>
          </Grid>
          {selectedInstructionalUnit && (
            <Grid size={{ md: 8, xs: 12 }}>
              <Outlet context={{ focusArea: focusArea, instructionalUnit: selectedInstructionalUnit }} />
            </Grid>
          )}
        </Grid>
      </Page>
    </>
  );
};

const SKELETON_TEXT_MAX_WIDTH = 250;
const SKELETON_TEXT_MIN_WIDTH = 100;
const UnitSkeletonItem = () => (
  <>
    <Stack direction="row" alignItems="center" gap={2} sx={{ padding: 1 }}>
      <Skeleton variant="rectangular" width="25px" height="25px" />
      <Skeleton
        variant="text"
        sx={{
          fontSize: '1rem',
          width: '100%',
          maxWidth: Math.floor(Math.random() * (SKELETON_TEXT_MAX_WIDTH - SKELETON_TEXT_MIN_WIDTH) + SKELETON_TEXT_MIN_WIDTH),
        }}
      />
    </Stack>
  </>
);

interface UnitRowProps {
  unit: InstructionalUnitResponse;
  focusArea: ScopeAndSequenceFocusArea;
  isSelected: boolean;
  primaryColour: string;
  secondaryColour: string;
}

const UnitRow = (props: UnitRowProps) => {
  const { unit, focusArea, isSelected, primaryColour, secondaryColour } = props;
  return (
    <Stack
      key={unit.id}
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        paddingX: 1,
        paddingY: 1,
        ...(isSelected && {
          paddingY: 3,
          borderColor: hootTokens.palette.success['100'],
          border: '2px solid',
          background: hootTokens.palette.success['160'],
          borderRadius: '8px',
        }),
      }}
    >
      <ScopeAndSequenceIcon fill={secondaryColour} stroke={primaryColour} />
      <HootTypography isPII={false} variant="labellarge">
        <Link replace to={routesDictionary.scopeAndSequence.focusArea.unit.url(focusArea.slug, unit.slug)}>
          {unit.name}
        </Link>
      </HootTypography>
    </Stack>
  );
};

const ScopeAndSequenceFocusAreaDetailPageWrapper = () => {
  const { focusAreaId } = useParams<{ focusAreaId: FocusAreaSlug }>();

  const navigate = useNavigate();

  const focusArea: ScopeAndSequenceFocusArea | undefined = scopeAndSequenceFocusAreaDictionary[focusAreaId as FocusAreaSlug];

  useEffect(() => {
    // If invalid focus ID, redirect back to the S&S page.
    if (!focusArea) {
      navigate(routesDictionary.scopeAndSequence.url, { replace: true });
    }
  }, [focusArea, navigate]);

  return focusArea ? <ScopeAndSequenceFocusAreaDetailPage focusArea={focusArea} /> : null;
};

export default ScopeAndSequenceFocusAreaDetailPageWrapper;
