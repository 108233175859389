import { IconButton, TextFieldProps, useMediaQuery, useTheme } from '@mui/material';
import { DatePickerProps, DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { DateFormats } from '@hoot/constants/constants';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import CalendarIcon from '../icons/CalendarIcon';

type CalendarFieldProps = Omit<DatePickerProps<DateTime>, 'onChange' | 'value'> & {
  onChange: (val: DateTime | null) => void;
  value: DateTime | null;
  helperText?: string;
  error?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
  disableKeyboardInput?: boolean;
};

function DatePicker(props: CalendarFieldProps) {
  const { helperText, error, textFieldProps, disableKeyboardInput = false, ...datePickerProps } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableKeyboardInput) {
      event.stopPropagation(); // will stop arrows up and down from changing the value
      event.preventDefault(); // will stop the left/right arrows in the input
    }
  };

  return (
    <MUIDatePicker
      format={DateFormats.FULL_MDY_0}
      {...datePickerProps}
      disableOpenPicker={isMobile}
      slots={{
        openPickerIcon: isMobile
          ? undefined
          : () => (
              <IconButton>
                <CalendarIcon htmlColor={error ? hootTokens.palette.error[80] : 'currentColor'} />
              </IconButton>
            ),
      }}
      slotProps={{
        textField: {
          ...textFieldProps,
          InputProps: {
            placeholder: textFieldProps?.placeholder,
            sx: {
              '& input': {
                padding: '24px 16px 8px',
              },
            },
          },
          onKeyDownCapture: handleKeyDown,
          helperText: helperText,
          error: error,
        },
      }}
    />
  );
}

export default DatePicker;
