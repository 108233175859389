import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from '@hoot/ui/components/v2/core/DatePicker';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface Props {
  open: boolean;
  onDismiss: () => void;
  onApply: (data: DateRangeFormValues) => void;
}

export interface DateRangeFormValues {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

const defaultValues: DateRangeFormValues = {
  startDate: null,
  endDate: null,
};

const DateRangeDialog = (props: Props) => {
  const { open, onDismiss, onApply } = props;

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<DateRangeFormValues>({
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    onDismiss();
    clearErrors();
  };

  const handleApply = (data: DateRangeFormValues) => {
    onApply(data);
    onDismiss();
  };

  return (
    <BasicAlertDialog
      title="Specific Date Filter"
      show={open}
      onDismiss={onDismiss}
      maxWidth={'sm'}
      content={
        <Stack direction="row" gap={2}>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: 'From Date is required',
            }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                label="From Date *"
                value={value}
                onChange={onChange}
                error={!!errors.startDate}
                helperText={errors.startDate?.message ?? ''}
              />
            )}
          />

          <Controller
            name="endDate"
            control={control}
            rules={{
              required: 'To Date is required',
            }}
            render={({ field: { value, onChange } }) => (
              <DatePicker label="To Date *" value={value} onChange={onChange} error={!!errors.endDate} helperText={errors.endDate?.message ?? ''} />
            )}
          />
        </Stack>
      }
      primaryAction={{
        label: 'Apply',
        onClick: handleSubmit(handleApply),
        props: {
          variant: 'contained',
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: handleClose,
      }}
    />
  );
};

export default DateRangeDialog;
