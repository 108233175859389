import { FrontEndFacingLessonStatusesLookup, FrontEndFacingScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DistrictRepresentativeScheduleQuery } from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeSchedule';
import CheckList, { CheckListItem } from '@hoot/ui/components/v2/CheckList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import DatePicker from '@hoot/ui/components/v2/core/DatePicker';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import { Select } from '@hoot/ui/components/v2/core/Select';
import TimePicker from '@hoot/ui/components/v2/core/TimePicker';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import EnrolmentFilter from '../students/EnrolmentFilter';
import DistrictRepScheduleSchoolFilter from './DistrictRepScheduleSchoolsFilter';

interface FilterDialogProps {
  show: boolean;
  query: DistrictRepresentativeScheduleQuery;
  onDismiss: () => void;
  onChange: React.Dispatch<React.SetStateAction<DistrictRepresentativeScheduleQuery>>;
}

export interface DistrictRepresentativeScheduleForm {
  date?: string;
  status?: FrontEndFacingScheduledLessonStatus;
  time?: string;
  school?: string;
  enrolmentId?: string;
}

const filterOptions = ['date', 'status', 'time', 'school', 'enrolmentId'] as const;
type FilterOptionsType = (typeof filterOptions)[number];

const DistrictRepScheduleFiltersDialog = (props: FilterDialogProps) => {
  const { show, onDismiss, query, onChange } = props;
  const [queryOptions, setQueryOptions] = useState<FilterOptionsType[]>(
    filterOptions.filter((option) => {
      const value = query[option];
      return Object.keys(query).includes(option) && ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value));
    }),
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<DistrictRepresentativeScheduleForm>({
    defaultValues: {
      date: query.date,
      status: query.status,
      school: query.school,
      enrolmentId: query.enrolmentId,
      time: query.time,
    },
  });

  const toggleOption = (option: CheckListItem) => {
    const optionSelected = queryOptions.includes(option.value as FilterOptionsType);
    if (optionSelected) {
      const filteredOptions = queryOptions.filter((qo) => qo !== option.value);
      setQueryOptions(filteredOptions);
      setValue(`${option.value as FilterOptionsType}`, undefined);
    } else {
      setQueryOptions([...queryOptions, option.value as FilterOptionsType]);
      setValue(`${option.value as FilterOptionsType}`, query[option.value as FilterOptionsType]);
    }
  };

  const onSubmit = (data: DistrictRepresentativeScheduleForm) => {
    onChange((prevState) => ({ ...prevState, ...data, page: 1 }));
    onDismiss();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="district-rep-schedule-filter-form">
      <Dialog open={show} onClose={onDismiss} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Filter
          <IconButton onClick={onDismiss} sx={{ color: hootTokens.palette.black }}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={3} justifyContent="space-between">
            <Stack gap={1} width="40%">
              <CheckList
                title="Select Filters"
                divider
                items={filterOptions.map((option) => ({
                  label: capitalCase(option),
                  value: option,
                }))}
                selectedValues={queryOptions}
                toggleOption={toggleOption}
              />
            </Stack>
            <Stack gap={1} width="60%">
              <HootTypography isPII={false} variant="titlesmall">
                Filters that will be applied
              </HootTypography>
              {queryOptions.length ? (
                queryOptions.map((option) => {
                  switch (option) {
                    case 'status':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'A selection is required',
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              label="Lesson Status"
                              value={value}
                              onChange={(event) => {
                                if (onChange) {
                                  onChange(event.target.value);
                                }
                              }}
                              error={!!errors.status}
                              helperText={errors.status?.message ?? ''}
                            >
                              {value === undefined ? <option value={undefined} /> : null}
                              {Object.entries(FrontEndFacingLessonStatusesLookup).map((status) => {
                                return (
                                  <option key={status[0]} value={status[0]}>
                                    {status[1]}
                                  </option>
                                );
                              })}
                            </Select>
                          )}
                        />
                      );
                    case 'date':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'Lesson Date is required',
                          }}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              label="Lesson Date"
                              value={value ? DateTime.fromSQL(value) : null}
                              onChange={(val) => onChange(val ? val.toSQLDate() : null)}
                              error={!!errors.date}
                              helperText={errors.date?.message ?? ''}
                            />
                          )}
                        />
                      );
                    case 'time':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'Lesson Time is required',
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TimePicker
                              label="Lesson Time"
                              value={value ? DateTime.fromFormat(value, 'h:mm a') : null}
                              onChange={(val) => onChange(val ? val.toFormat('h:mm a') : null)}
                              error={!!errors.time}
                              helperText={errors.time?.message ?? ''}
                            />
                          )}
                        />
                      );
                    case 'school':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'School is required',
                          }}
                          render={({ field: { value, onChange } }) => (
                            <DistrictRepScheduleSchoolFilter
                              label="School"
                              value={value}
                              onChange={onChange}
                              error={!!errors.school}
                              helperText={errors.school?.message ?? ''}
                            />
                          )}
                        />
                      );
                    case 'enrolmentId':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'Enrolment is required',
                          }}
                          render={({ field: { value, onChange } }) => (
                            <EnrolmentFilter
                              label="Enrolment Id"
                              value={value}
                              onChange={onChange}
                              error={!!errors.enrolmentId}
                              helperText={errors.enrolmentId?.message ?? ''}
                            />
                          )}
                        />
                      );
                    default:
                      return null;
                  }
                })
              ) : (
                <HootTypography isPII={false} variant="bodysmall">
                  No filters selected
                </HootTypography>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onDismiss} variant="outlined" size="medium">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="medium" form="district-rep-schedule-filter-form">
            Apply ({queryOptions && queryOptions.length})
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default DistrictRepScheduleFiltersDialog;
