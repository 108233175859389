import { TimelineEvent, TimelineOutcome } from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { DateFormats, WINNIPEG_TIMEZONE } from '@hoot/constants/constants';
import { EnrolmentPeriodType, HeaderCell, StudentTimelineResponse, TimelineCell } from '@hoot/hooks/api/my-student/getStudentTimeline';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag from '@hoot/ui/components/v2/core/Tag';
import DotsIcon from '@hoot/ui/components/v2/icons/timeline-legend/DotsIcon';
import ExpandButtonIcon from '@hoot/ui/components/v2/icons/timeline-legend/ExpandButtonIcon';
import PinIcon from '@hoot/ui/components/v2/icons/timeline-legend/PinIcon';
import {
  RapportAndRelationship,
  timelineEventIconDictionary,
  timelineIconsDictionary,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/common';
import InfoBubbles from './InfoBubbles';
import UnitDialog from './UnitDialog';

interface TimelineGridProps {
  data: StudentTimelineResponse | undefined;
  studentProfileId: string;
  onExpand?: () => void;
}

const TimelineGrid = (props: TimelineGridProps) => {
  const { data, studentProfileId, onExpand } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedOutcomeCell, setSelectedOutcomeCell] = useState<TimelineCell | null>(null);
  const [selectedEventCell, setSelectedEventCell] = useState<HeaderCell | null>(null);
  const [unitClicked, setUnitClicked] = useState<string | null>(null);

  const handleHeaderClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: HeaderCell) => {
    setAnchorEl(event.currentTarget);
    setSelectedEventCell(cell);
  };

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: TimelineCell) => {
    if (cell.outcome !== TimelineOutcome.Blank) {
      setAnchorEl(event.currentTarget);
      setSelectedOutcomeCell(cell);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedOutcomeCell(null);
    setSelectedEventCell(null);
  };

  const onUnitClick = (unitName: string) => setUnitClicked(unitName);
  const onUnitDialogDismiss = () => setUnitClicked(null);

  const ignoreUnit = unitClicked === RapportAndRelationship;

  return (
    <Stack sx={{ overflowX: 'auto', marginTop: 2 }}>
      <Stack sx={{ background: 'white', height: '114px', width: '254px', position: 'sticky', left: 0, zIndex: 1, mt: '24px' }} />
      {data?.modules.map((module, moduleIndex) => (
        <Stack direction="row" sx={{ marginBottom: 2, left: 0, width: 'fit-content', '&:last-child': { marginBottom: 0 } }}>
          <Stack key={moduleIndex} direction="row" sx={{ position: 'sticky', left: 0, zIndex: 1, background: 'white' }}>
            {/* Vertical module name section */}
            <Tag
              label={module.name}
              sx={{
                alignSelf: 'center',
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
              }}
            />

            {/* Unit name section */}
            <Stack sx={{ paddingLeft: 2, minWidth: '220px' }} alignSelf="center">
              {module.units.map((unit, unitIndex) => {
                const ignoreUnit = unit.name === RapportAndRelationship;

                return (
                  <Stack key={unitIndex} direction="row" onClick={() => onUnitClick(unit.name)} role="button" sx={{ height: '24px' }}>
                    <HootTypography
                      variant="tableheading"
                      isPII={false}
                      sx={{
                        cursor: ignoreUnit ? 'text' : 'pointer',
                        '&:hover': { textDecoration: ignoreUnit ? 'none' : 'underline' },
                      }}
                    >
                      {unit.name}
                    </HootTypography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          {/* Table */}
          <Stack sx={{ borderRight: '1px solid #000' }} alignSelf="center" marginTop={moduleIndex === 0 ? '-24px' : undefined}>
            {/* Table header */}
            {moduleIndex === 0 ? (
              <>
                <Stack direction="row">
                  {/* Header Row cells & Assessment Period Date */}
                  {data?.headerRow.map((cell, colIndex) =>
                    cell ? (
                      <Stack
                        key={colIndex}
                        sx={{
                          borderTop: '1px solid #000',
                          borderLeft: '1px solid #000',
                          borderBottom: '1px solid #000',
                          position: 'relative',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={(event) => handleHeaderClick(event, cell)}
                      >
                        <DatePin cell={cell} />

                        {/* Header Icon cell */}
                        {timelineEventIconDictionary[cell.event]}
                      </Stack>
                    ) : (
                      <Stack
                        key={colIndex}
                        sx={{
                          borderLeft: '1px solid #000',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <ExpandButtonIcon onClick={onExpand} />
                      </Stack>
                    ),
                  )}
                </Stack>
              </>
            ) : null}

            {/* Table rows */}
            {module.units.map((unit, unitIndex) => (
              <Stack direction="row" key={unitIndex}>
                {unit.cells.map((cell, colIndex) =>
                  cell ? (
                    <Stack
                      key={colIndex}
                      sx={{
                        borderTop: moduleIndex !== 0 && unitIndex === 0 ? '1px solid #000' : undefined,
                        borderLeft: '1px solid #000',
                        borderBottom: '1px solid #000',
                        '&:hover': {
                          cursor: cell.outcome === TimelineOutcome.Blank ? undefined : 'pointer',
                        },
                      }}
                      onClick={(event) => handleRowClick(event, cell)}
                    >
                      {timelineIconsDictionary[cell.outcome]}
                    </Stack>
                  ) : (
                    <Stack
                      key={colIndex}
                      sx={{
                        borderLeft: '1px solid #000',
                      }}
                    >
                      <DotsIcon />
                    </Stack>
                  ),
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}

      {/* Dialogs */}
      <InfoBubbles selectedOutcomeCell={selectedOutcomeCell} selectedEventCell={selectedEventCell} anchorEl={anchorEl} onClose={handlePopoverClose} />
      {unitClicked && !ignoreUnit ? <UnitDialog studentProfileId={studentProfileId} unitName={unitClicked} onDismiss={onUnitDialogDismiss} /> : null}
    </Stack>
  );
};

/**
 * We only render the pin when:
 * - a. The cell is an HRA or PMA type and we have a date set.
 * - b. The cell is a ScheduledLesson type and we have an enrolment period start date.
 * - Otherwise, don't show the pin.
 */
const DatePin = (props: { cell: HeaderCell }) => {
  const {
    cell: { date, enrolmentPeriodStart, enrolmentPeriodType },
  } = props;
  const isAssessmentEvent = [TimelineEvent.HRA, TimelineEvent.PMA].includes(props.cell.event);
  const isScheduledLesson = props.cell.event === TimelineEvent.ScheduledLesson;

  if ((isAssessmentEvent && !date) || (isScheduledLesson && !enrolmentPeriodStart) || (!isAssessmentEvent && !isScheduledLesson && date)) return null;

  return (
    <Stack alignItems="center" position="relative">
      {isAssessmentEvent && date ? <AssessmentLabel date={date} /> : null}
      {isScheduledLesson && enrolmentPeriodStart && enrolmentPeriodType ? (
        <PeriodLabel enrolmentPeriodType={enrolmentPeriodType} enrolmentPeriodStart={enrolmentPeriodStart} />
      ) : null}
      <PinIcon sx={{ position: 'absolute', top: '-24px' }} />
    </Stack>
  );
};

const AssessmentLabel = (props: { date: string | undefined }) => {
  if (!props.date) return;

  return (
    <HootTypography
      variant="bodysmall"
      isPII={false}
      sx={{
        position: 'absolute',
        top: '-76px',
        left: '-6px',
        rotate: '-45deg',
        minWidth: '100px',
      }}
    >
      {DateTime.fromFormat(props.date, 'MMMM d, yyyy').toFormat(DateFormats.SHORT_MDY)}
    </HootTypography>
  );
};

const PeriodLabel = (props: { enrolmentPeriodType: EnrolmentPeriodType; enrolmentPeriodStart: number | undefined }) => {
  if (!props.enrolmentPeriodStart) return;

  return (
    <>
      <HootTypography
        variant="tableheadingactive"
        isPII={false}
        sx={{
          position: 'absolute',
          top: '-60px',
          minWidth: '116px',
        }}
      >
        {props.enrolmentPeriodType} Period Start
      </HootTypography>
      <HootTypography
        variant="bodysmall"
        textAlign="center"
        isPII={false}
        sx={{
          position: 'absolute',
          top: '-44px',
          minWidth: '100px',
        }}
      >
        {DateTime.fromMillis(props.enrolmentPeriodStart).setZone(WINNIPEG_TIMEZONE).toFormat(DateFormats.SHORT_MDY)}
      </HootTypography>
    </>
  );
};

export default TimelineGrid;
