import { SvgIconProps } from '@mui/material';
import AccountFilledIcon from '@hoot/ui/components/v2/icons/AccountFilledIcon';
import CenterCameraIcon from '@hoot/ui/components/v2/icons/CenterCameraIcon';
import ClockIcon from '@hoot/ui/components/v2/icons/ClockIcon';
import CollapseIcon from '@hoot/ui/components/v2/icons/CollapseIcon';
import CollectionsFilled from '@hoot/ui/components/v2/icons/CollectionsFilled';
import CropLandscape from '@hoot/ui/components/v2/icons/CropLandscape';
import CropPortrait from '@hoot/ui/components/v2/icons/CropPortrait';
import EnterFullScreenFilledIcon from '@hoot/ui/components/v2/icons/EnterFullScreenFilledIcon';
import EraserIcon from '@hoot/ui/components/v2/icons/EraserIcon';
import FolderDownIcon from '@hoot/ui/components/v2/icons/FolderDownIcon';
import GearIcon from '@hoot/ui/components/v2/icons/GearIcon';
import MenuDropDownIcon from '@hoot/ui/components/v2/icons/MenuDropDownIcon';
import NewTabIcon from '@hoot/ui/components/v2/icons/NewTabIcon';
import PageWhiteIcon from '@hoot/ui/components/v2/icons/PageWhiteIcon';
import PushPinIcon from '@hoot/ui/components/v2/icons/PushPin';
import SchoolBelIcon from '@hoot/ui/components/v2/icons/SchoolBelIcon';
import ScopeAndSequenceIcon from '@hoot/ui/components/v2/icons/ScopeAndSequenceIcon';
import TrashBinIcon from '@hoot/ui/components/v2/icons/TrashBinIcon';
import TypeLettersIcon from '@hoot/ui/components/v2/icons/TypeLettersIcon';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { getColors } from '@hoot/ui/theme/v2/util';
import AccountCircleFilledIcon from '../icons/AccountCircleFilledIcon';
import AddIcon from '../icons/AddIcon';
import ArrowIcon from '../icons/ArrowIcon';
import BeakerIcon from '../icons/BeakerIcon';
import BellNotificationIcon from '../icons/BellNotificationIcon';
import BellSolidIcon from '../icons/BellSolidIcon';
import BookDoubleIcon from '../icons/BookDoubleIcon';
import BookmarkAddIcon from '../icons/BookmarkAddIcon';
import BookmarkAddedIcon from '../icons/BookmarkAddedIcon';
import CalendarIcon from '../icons/CalendarIcon';
import Camera from '../icons/Camera';
import CameraOffIcon from '../icons/CameraOff';
import CameraOnIcon from '../icons/CameraOn';
import CancelIcon from '../icons/CancelIcon';
import CancelIconFilled from '../icons/CancelIconFilled';
import CheckIcon from '../icons/CheckIcon';
import CheckboxFilledIcon from '../icons/CheckboxFilledIcon';
import CheckboxOutlineBlankIcon from '../icons/CheckboxOutlineBlankIcon';
import CheckmarkSquare from '../icons/CheckmarkSquare';
import Chevron from '../icons/Chevron';
import ClipboardIcon from '../icons/ClipboardIcon';
import CloseFilledSquareIcon from '../icons/CloseFilledSquareIcon';
import CloseIcon from '../icons/CloseIcon';
import CloseSquare from '../icons/CloseSquare';
import Collections from '../icons/Collections';
import CollectionsColoured from '../icons/CollectionsColoured';
import CompassIcon from '../icons/CompassIcon';
import Copy from '../icons/Copy';
import DistrictIcon from '../icons/DistrictIcon';
import DividerCaliper from '../icons/DividerCaliper';
import DragIcon from '../icons/DragIcon';
import EnterFullScreenIcon from '../icons/EnterFullScreenIcon';
import ErrorIcon from '../icons/ErrorIcon';
import ExitFullScreenIcon from '../icons/ExitFullScreenIcon';
import FavouriteFilledIcon from '../icons/FavouriteFilledIcon';
import FavouriteIcon from '../icons/FavouriteIcon';
import FilterDetailedFilledIcon from '../icons/FilterDetailedFilledIcon';
import FilterDetailedIcon from '../icons/FilterDetailedIcon';
import FilterIcon from '../icons/FilterIcon';
import GalleryViewIcon from '../icons/GalleryViewIcon';
import HeartFilledIcon from '../icons/HeartFilledIcon';
import HideIcon from '../icons/HideIcon';
import HootWheel from '../icons/HootWheel';
import InProgressIcon from '../icons/InProgressIcon';
import InfoFilledIcon from '../icons/InfoFilledIcon';
import InvoiceIconBlue from '../icons/InvoiceIconBlue';
import LessonIcon from '../icons/LessonIcon';
import ListViewIcon from '../icons/ListViewIcon';
import LocationPinIcon from '../icons/LocationPinIcon';
import LockedIcon from '../icons/LockedIcon';
import LogoutIcon from '../icons/LogoutIcon';
import MenuIcon from '../icons/MenuIcon';
import MicOffIcon from '../icons/MicOffIcon';
import MicOnIcon from '../icons/MicOnIcon';
import Microphone from '../icons/Microphone';
import MinusCircleIcon from '../icons/MinusCircle';
import { NetworkFull, NetworkHalf, NetworkLow } from '../icons/NetworkIcons';
import NewTabIcon2 from '../icons/NewTabIcon2';
import NotStartedIcon from '../icons/NotStartedIcon';
import PageIcon from '../icons/PageIcon';
import PenIcon from '../icons/PenIcon';
import PhoneIcon from '../icons/PhoneIcon';
import PlayIcon from '../icons/PlayIcon';
import PlusCircleIcon from '../icons/PlusCircle';
import RadioButtonSelectedIcon from '../icons/RadioButtonSelectedIcon';
import RadioButtonUnselectedIcon from '../icons/RadioButtonUnselectedIcon';
import ReadIcon from '../icons/ReadIcon';
import RedoIcon from '../icons/RedoIcon';
import SearchFilledIcon from '../icons/SearchFilledIcon';
import SearchIcon from '../icons/SearchIcon';
import ShowIcon from '../icons/ShowIcon';
import SinglePageIcon from '../icons/SinglePageIcon';
import SolidAddRectangleIcon from '../icons/SolidAddRectangleIcon';
import SpeechIcon from '../icons/SpeechIcon';
import UndoRedo from '../icons/UndoRedo';
import UnlockedIcon from '../icons/UnlockedIcon';
import WhiteboardIcon from '../icons/Whiteboard';
import ZoomInIcon from '../icons/ZoomInIcon';

export type IconName =
  | 'account_circle_filled'
  | 'account_filled'
  | 'add'
  | 'arrow_icon'
  | 'beaker'
  | 'bell_notification'
  | 'bell_solid'
  | 'book_double'
  | 'bookmark_added'
  | 'bookmark_add'
  | 'calendar'
  | 'calendar_round'
  | 'camera_icon'
  | 'camera_on'
  | 'camera_off'
  | 'cancel'
  | 'cancel_icon_filled'
  | 'center_camera'
  | 'check_box_filled'
  | 'check_box_outline_blank'
  | 'check'
  | 'checkmark_square'
  | 'chevron'
  | 'clipboard'
  | 'clock'
  | 'close'
  | 'close_filled_square'
  | 'close_square'
  | 'collapse_filled'
  | 'compass'
  | 'collections'
  | 'collections_filled'
  | 'collections-coloured'
  | 'copy'
  | 'crop_landscape'
  | 'crop_portrait'
  | 'district'
  | 'divider_caliper'
  | 'drag'
  | 'enter_full_screen'
  | 'enter_full_screen_filled'
  | 'eraser'
  | 'error'
  | 'exit_full_screen'
  | 'favourite'
  | 'favourite_filled'
  | 'filter'
  | 'filter_detailed'
  | 'filter_detailed_filled'
  | 'gallery_view'
  | 'folder_down'
  | 'gear'
  | 'heart_filled'
  | 'hide'
  | 'hoot_wheel'
  | 'info_filled'
  | 'in_progress'
  | 'invoice'
  | 'lesson_icon'
  | 'list_view'
  | 'location_pin'
  | 'locked'
  | 'logout'
  | 'menu'
  | 'menu_drop_down_icon'
  | 'microphone'
  | 'mic_off'
  | 'mic_on'
  | 'minus_circle'
  | 'network_full'
  | 'network_half'
  | 'network_low'
  | 'new_tab'
  | 'new_tab_2'
  | 'not_started'
  | 'page'
  | 'page_white'
  | 'pen'
  | 'phone'
  | 'play'
  | 'plus_circle'
  | 'push_pin'
  | 'radio_button_selected'
  | 'radio_button_unselected'
  | 'read'
  | 'redo'
  | 'school_bell'
  | 'scope_and_sequence'
  | 'search'
  | 'search_filled'
  | 'show'
  | 'solid_add_rectangle'
  | 'single_page'
  | 'speech_icon'
  | 'trash_bin'
  | 'type_letters'
  | 'undo_redo'
  | 'unlocked'
  | 'whiteboard'
  | 'zoom_in';

export const icons = new Map<IconName, React.FC<SvgIconProps>>([
  ['account_circle_filled', AccountCircleFilledIcon],
  ['account_filled', AccountFilledIcon],
  ['add', AddIcon],
  ['arrow_icon', ArrowIcon],
  ['beaker', BeakerIcon],
  ['bell_notification', BellNotificationIcon],
  ['bell_solid', BellSolidIcon],
  ['book_double', BookDoubleIcon],
  ['bookmark_added', BookmarkAddedIcon],
  ['bookmark_add', BookmarkAddIcon],
  ['calendar', CalendarIcon],
  ['camera_icon', Camera],
  ['camera_on', CameraOnIcon],
  ['camera_off', CameraOffIcon],
  ['cancel', CancelIcon],
  ['cancel_icon_filled', CancelIconFilled],
  ['center_camera', CenterCameraIcon],
  ['check_box_filled', CheckboxFilledIcon],
  ['check_box_outline_blank', CheckboxOutlineBlankIcon],
  ['check', CheckIcon],
  ['checkmark_square', CheckmarkSquare],
  ['chevron', Chevron],
  ['clipboard', ClipboardIcon],
  ['clock', ClockIcon],
  ['close', CloseIcon],
  ['close_filled_square', CloseFilledSquareIcon],
  ['close_square', CloseSquare],
  ['collapse_filled', CollapseIcon],
  ['collections', Collections],
  ['collections_filled', CollectionsFilled],
  ['collections-coloured', CollectionsColoured],
  ['compass', CompassIcon],
  ['copy', Copy],
  ['crop_landscape', CropLandscape],
  ['crop_portrait', CropPortrait],
  ['district', DistrictIcon],
  ['divider_caliper', DividerCaliper],
  ['drag', DragIcon],
  ['enter_full_screen', EnterFullScreenIcon],
  ['enter_full_screen_filled', EnterFullScreenFilledIcon],
  ['eraser', EraserIcon],
  ['error', ErrorIcon],
  ['exit_full_screen', ExitFullScreenIcon],
  ['favourite', FavouriteIcon],
  ['favourite_filled', FavouriteFilledIcon],
  ['filter', FilterIcon],
  ['filter_detailed', FilterDetailedIcon],
  ['filter_detailed_filled', FilterDetailedFilledIcon],
  ['gallery_view', GalleryViewIcon],
  ['folder_down', FolderDownIcon],
  ['gear', GearIcon],
  ['heart_filled', HeartFilledIcon],
  ['hide', HideIcon],
  ['hoot_wheel', HootWheel],
  ['info_filled', InfoFilledIcon],
  ['in_progress', InProgressIcon],
  ['invoice', InvoiceIconBlue],
  ['lesson_icon', LessonIcon],
  ['list_view', ListViewIcon],
  ['location_pin', LocationPinIcon],
  ['locked', LockedIcon],
  ['logout', LogoutIcon],
  ['menu', MenuIcon],
  ['menu_drop_down_icon', MenuDropDownIcon],
  ['mic_off', MicOffIcon],
  ['mic_on', MicOnIcon],
  ['microphone', Microphone],
  ['minus_circle', MinusCircleIcon],
  ['network_full', NetworkFull],
  ['network_half', NetworkHalf],
  ['network_low', NetworkLow],
  ['new_tab', NewTabIcon],
  ['new_tab_2', NewTabIcon2],
  ['not_started', NotStartedIcon],
  ['page', PageIcon],
  ['page_white', PageWhiteIcon],
  ['pen', PenIcon],
  ['phone', PhoneIcon],
  ['play', PlayIcon],
  ['plus_circle', PlusCircleIcon],
  ['push_pin', PushPinIcon],
  ['radio_button_selected', RadioButtonSelectedIcon],
  ['radio_button_unselected', RadioButtonUnselectedIcon],
  ['read', ReadIcon],
  ['redo', RedoIcon],
  ['school_bell', SchoolBelIcon],
  ['scope_and_sequence', ScopeAndSequenceIcon],
  ['search', SearchIcon],
  ['search_filled', SearchFilledIcon],
  ['show', ShowIcon],
  ['solid_add_rectangle', SolidAddRectangleIcon],
  ['single_page', SinglePageIcon],
  ['speech_icon', SpeechIcon],
  ['trash_bin', TrashBinIcon],
  ['type_letters', TypeLettersIcon],
  ['undo_redo', UndoRedo],
  ['unlocked', UnlockedIcon],
  ['whiteboard', WhiteboardIcon],
  ['zoom_in', ZoomInIcon],
]);

export type IconProps = Omit<SvgIconProps, 'color'> & {
  name: IconName;
  color?: ColorPaletteOption;
};

export function Icon(props: IconProps) {
  const { name, color, ...rest } = props;
  const Component = icons.get(name) ?? icons.get('account_circle_filled')!;

  const componentColor = color ? getColors(color || 'primary').base : undefined;
  return <Component htmlColor={componentColor} {...rest} />;
}
