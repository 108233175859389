import {
  EventVisibilityOption,
  FocusVisibilityOption,
  TimelineEvent,
  TimelineFilterType,
  TimelineLessonReviewFocus,
  TimelineOutcome,
  TimelineViewOption,
} from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface TimelineCell {
  outcome: TimelineOutcome | TimelineLessonReviewFocus;
  date?: string;
  teacherName?: string;
  lessonReviewId?: string;
  hraId?: string;
  pmaId?: string;
  unit: {
    name: string;
    id: string;
    results?: string;
  };
}

export enum EnrolmentPeriodType {
  HRA = 'HRA',
  PMA = 'PMA',
}

export interface HeaderCell {
  event: TimelineEvent;
  date?: string;
  teacherName?: string;
  lessonReviewId?: string;
  hraId?: string;
  pmaId?: string;
  enrolmentPeriodStart?: number;
  enrolmentPeriodType?: EnrolmentPeriodType;
}

interface AssessmentUnit {
  name: string;
  sequence: number;
  cells: (TimelineCell | null)[];
}

interface AssessmentModule {
  name: string;
  sequence: number;
  units: AssessmentUnit[];
}

export interface StudentTimelineResponse {
  headerRow: (HeaderCell | null)[];
  modules: AssessmentModule[];
}

export interface StudentTimelineRequest {
  filterType: TimelineFilterType;
  viewOption: TimelineViewOption;
  fromDate?: number;
  toDate?: number;
  eventVisibility?: EventVisibilityOption[];
  focusVisibility?: FocusVisibilityOption[];
}

export async function getStudentTimeline(studentProfileId: string, query: StudentTimelineRequest): Promise<StudentTimelineResponse> {
  const { data } = await axios.get<StudentTimelineResponse>(`${config.apiUrl}/my-students/${studentProfileId}/timeline`, { params: query });
  return data;
}

export default function useGetStudentTimeline(
  studentProfileId: string,
  query: StudentTimelineRequest,
  options?: Omit<UseQueryOptions<StudentTimelineResponse, AxiosError>, 'queryKey' | 'queryFn'>,
): UseQueryResult<StudentTimelineResponse> {
  return useQuery([QueryKey.GetStudentTimeline, studentProfileId, query], () => getStudentTimeline(studentProfileId, query), options);
}
