import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { QueryKey } from '../../queryKeys';
import { SchoolReportingFilters } from './useGetSchoolReportingMetrics';

export enum StudentReportingMetricSortKey {
  StudentName = 'studentName',
  StudentNumber = 'studentNumber',
  AttendanceRate = 'averageAttendanceRate',
  LateJoinRate = 'lateJoinRate',
  MinutesInLesson = 'minutesInLesson',
  FirstLesson = 'firstLesson',
  MostRecentLesson = 'mostRecentLesson',
}

interface StudentReportingMetricData {
  studentName: string;
  studentNumber: number;
  averageAttendanceRate: number;
  lateJoinRate: number;
  minutesInLesson: number;
  firstLesson: string;
  mostRecentLesson: string;
}

export interface StudentReportingRequest {
  page: number;
  pageSize: number;
  sortBy: keyof StudentReportingMetricData;
  orderBy: OrderBy;
  filters?: SchoolReportingFilters;
}

interface StudentReportingMetricResponse {
  data: StudentReportingMetricData[];
  count: number;
  page: number;
  pageSize: number;
}

export default function useGetStudentReportingMetrics(
  schoolId: string,
  paginationQuery: StudentReportingRequest,
  filtersQuery: SchoolReportingFilters,
  options?: Omit<UseQueryOptions<StudentReportingMetricResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  const reportingRequestParams: StudentReportingRequest = { ...paginationQuery, filters: filtersQuery };

  return useQuery(
    [QueryKey.GetDistrictRepReportingSchoolStudentMetrics, schoolId, paginationQuery, filtersQuery],
    async () => {
      return (
        await axios.get<StudentReportingMetricResponse>(
          `${config.apiUrl}/public/district-representative/reporting/schools/${schoolId}/student-metrics`,
          { params: reportingRequestParams },
        )
      ).data;
    },
    options,
  );
}
