import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LessonReviewFocus } from '@hoot/models/api/enums/lesson-review-enums';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import {
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import LessonReviewWarningCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewWarningCard';

interface LessonReviewStructureForm {
  assessment: boolean;
  instruction: boolean;
  rapportBuilding: boolean;
}

const assessmentFocusType: Record<string, LessonReviewFocus> = {
  [ScheduledLessonType.Assessment]: LessonReviewFocus.HootReadingAssessment,
  [ScheduledLessonType.ProgressMonitoring]: LessonReviewFocus.ProgressMonitoringAssessment,
};

const assessmentTile: Record<string, string> = {
  [ScheduledLessonType.Assessment]: 'Hoot Reading Assessment',
  [ScheduledLessonType.ProgressMonitoring]: 'Progress Monitoring Assessment',
};

const CheckBoxLabelNode = (props: { title: string; text: string }) => {
  const { title, text } = props;
  return (
    <Stack sx={{ pl: 1 }} alignContent="center">
      <HootTypography isPII={false} variant="titlemedium">
        {title}
      </HootTypography>
      <HootTypography isPII={false} variant="bodymedium">
        {text}
      </HootTypography>
    </Stack>
  );
};

const LessonReviewLessonStructureStep = () => {
  const {
    lessonToReview,
    lessonStructureState,
    actions: { goToNextStep, goToPreviousStep, setLessonStructure, attachInstructionalFocusData, getCurrentStepLabel },
  } = useLessonReviewWizardContext();

  const { control, watch } = useForm<LessonReviewStructureForm>({
    defaultValues: {
      assessment: lessonStructureState?.assessment ?? false,
      instruction: lessonStructureState?.instruction ?? false,
      rapportBuilding: lessonStructureState?.rapportBuilding ?? false,
    },
  });

  const { assessment, instruction, rapportBuilding } = watch();
  const allSelected = assessment && instruction && rapportBuilding;
  const canGoToNextStep = (assessment || instruction || rapportBuilding) && !allSelected;
  const isAssessmentLesson =
    lessonToReview?.lessonType && [ScheduledLessonType.Assessment, ScheduledLessonType.ProgressMonitoring].includes(lessonToReview.lessonType);
  const assessmentTitle =
    lessonToReview?.lessonType && [ScheduledLessonType.Assessment, ScheduledLessonType.ProgressMonitoring].includes(lessonToReview.lessonType)
      ? assessmentTile[lessonToReview?.lessonType]
      : '';
  const isAssessmentReviewOnly = assessment && !instruction && !rapportBuilding;

  useEffect(() => {
    setLessonStructure({ assessment, instruction, rapportBuilding });
  }, [assessment, instruction, rapportBuilding]);

  const submitAndGoToNextStep = () => {
    if (isAssessmentReviewOnly && isAssessmentLesson) {
      attachInstructionalFocusData({
        primaryFocus: assessmentFocusType[lessonToReview?.lessonType!],
        secondaryFocus: undefined,
        primaryFocusBooks: [],
        secondaryFocusBooks: [],
        primaryFocusUnit: undefined,
        secondaryFocusUnit: undefined,
        primaryFocusSkill: undefined,
        secondaryFocusSkill: undefined,
        noResourcesUsed: true,
        lessonReviewVersion: 2,
      });
    }
    goToNextStep();
  };

  return (
    <>
      <DialogContent>
        <Stack gap={2} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            {`Step ${getCurrentStepLabel(LessonReviewWizardStepEnum.LessonStructure)} - Lesson Structure`}
          </HootTypography>
          <Stack gap={1}>
            <HootTypography isPII={false} variant="bodymedium">
              What was done in the lesson time with the student? (Select up to 2 options)
            </HootTypography>
            <Stack direction={'column'} gap={'16px'}>
              {allSelected ? (
                <HootTypography isPII={false} variant="bodymedium" color="error">
                  This should not exceed 2 selections
                </HootTypography>
              ) : null}
              {isAssessmentLesson ? (
                <Controller
                  name="assessment"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value || false}
                      onChange={(e) => field.onChange(e.target.checked)}
                      label={
                        <CheckBoxLabelNode title={assessmentTitle} text="Conducted part or all of a Hoot Reading Assessment with the student." />
                      }
                      error={allSelected}
                    />
                  )}
                />
              ) : null}

              <Controller
                name="instruction"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value || false}
                    onChange={(e) => field.onChange(e.target.checked)}
                    label={<CheckBoxLabelNode title="Instruction" text="Focused time on reading instruction with the student." />}
                    error={allSelected}
                  />
                )}
              />
              <Controller
                name="rapportBuilding"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value || false}
                    onChange={(e) => field.onChange(e.target.checked)}
                    label={
                      <CheckBoxLabelNode
                        title="Rapport Building"
                        text="Spent more than 5 minutes focused on building a positive connection with the student."
                      />
                    }
                    error={allSelected}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack gap={2} flex={1} minHeight="327px">
            {allSelected ? (
              <LessonReviewWarningCard
                text={
                  <HootTypography isPII={false} variant="bodylarge">
                    Too many focuses
                  </HootTypography>
                }
              />
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!canGoToNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewLessonStructureStep;
