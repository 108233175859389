import { Box, Stack, Typography, keyframes, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { DAYS_OF_WEEK } from '../../../../constants/constants';
import { spliceChunk } from '../../../../utils/array';
import { hootTokens } from '../../../theme/v2/tokens';
import { Icon } from '../core/Icon';
import IconButton from '../core/IconButton';

export function CalendarV3(props: {
  month: DateTime;
  onMonthChange: (month: DateTime) => void;
  renderCalendarDate: (date: DateTime) => React.ReactNode;
  onDateClick: (date: DateTime) => () => void;
  header?: React.ReactNode;
  isLoading?: boolean;
  footer?: React.ReactNode;
}) {
  const now = DateTime.now();

  const startDate = props.month.startOf('week').minus({ day: 1 });
  const endDate = props.month.endOf('month').endOf('week').minus({ day: 1 });
  const dates = spliceChunk(generateDateRange(startDate, endDate), 7);

  const handlePrevClick = () => {
    props.onMonthChange(props.month.minus({ month: 1 }));
  };

  const handleNextClick = () => {
    props.onMonthChange(props.month.plus({ month: 1 }));
  };

  return (
    <Stack position="relative">
      <Stack position="relative">
        {props.isLoading ? <LoadingAnimation /> : null}
        <Calendar sx={{ marginTop: '32px', position: 'relative' }}>
          <CalendarHeader
            sx={{
              position: 'sticky',
              backgroundColor: hootTokens.palette.white,
              top: 0,
              boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 3px -2px rgba(0, 0, 0, 0.30)',
            }}
          >
            <CalendarRow>
              <CalendarHeaderCell colSpan={7}>
                <Header onNextClick={handleNextClick} onPrevClick={handlePrevClick} currentMonth={props.month} header={props.header} />
              </CalendarHeaderCell>
            </CalendarRow>
            <CalendarRow>
              {DAYS_OF_WEEK.map((header) => (
                <CalendarHeaderCell>
                  <Typography sx={{ marginBottom: 1 }} variant="titlesmall" color={`${hootTokens.palette.neutral[80]}`}>
                    {header}
                  </Typography>
                </CalendarHeaderCell>
              ))}
            </CalendarRow>
          </CalendarHeader>
          <CalendarBody>
            {dates.map((dateRow) => (
              <CalendarRow>
                {dateRow.map((date) => (
                  <CalendarCell onClick={props.onDateClick(date)}>
                    {date.toISODate() === now.toISODate() ? (
                      <Stack
                        padding="16px"
                        sx={{ height: '100%', width: '100%', border: `2px solid ${hootTokens.palette.secondary[100]}}` }}
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing="8px"
                      >
                        <Typography
                          textAlign="center"
                          sx={{
                            borderRadius: '8px',
                            backgroundColor: hootTokens.palette.secondary[60],
                            color: hootTokens.palette.white,
                            width: '37px',
                            height: '33px',
                          }}
                          variant="titlelarge"
                        >
                          {date.toFormat('d')}
                        </Typography>
                        {props.renderCalendarDate(date)}
                      </Stack>
                    ) : (
                      <Stack padding="16px" sx={{ height: '100%', width: '100%' }} alignItems="center" justifyContent="flex-start" spacing="8px">
                        {date.month === props.month.month ? (
                          <Typography variant="titlelarge">{date.toFormat('d')}</Typography>
                        ) : (
                          <Typography color={`${hootTokens.palette.neutral[80]}`} variant="titlelarge">
                            {date.toFormat('d')}
                          </Typography>
                        )}
                        {props.renderCalendarDate(date)}
                      </Stack>
                    )}
                  </CalendarCell>
                ))}
              </CalendarRow>
            ))}
          </CalendarBody>
        </Calendar>
      </Stack>
      {props.footer}
    </Stack>
  );
}

function Header(props: { currentMonth: DateTime; onNextClick: () => void; onPrevClick: () => void; header?: React.ReactNode }) {
  return (
    <Stack justifyContent="center" direction="row" sx={{ mb: 2 }}>
      <Box flex={1} />
      <Stack flex={1} direction="row" justifyContent="center" padding="16px">
        <IconButton
          variant="standard"
          sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
          onClick={props.onPrevClick}
        >
          <Icon name="chevron" />
        </IconButton>
        <Typography textAlign="center" sx={{ width: '400px' }} variant="displaysmall">
          {props.currentMonth.toFormat('LLLL yyyy')}
        </Typography>
        <IconButton
          variant="standard"
          sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
          onClick={props.onNextClick}
        >
          <Icon name="chevron" sx={{ rotate: '180deg' }} />
        </IconButton>
      </Stack>

      <Stack flex={1} direction="row" justifyContent="flex-end">
        {props.header}
      </Stack>
    </Stack>
  );
}

function generateDateRange(startDate: DateTime, endDate: DateTime) {
  if (endDate < startDate) {
    return [];
  }

  const results: DateTime[] = [];
  let currentDate = startDate;
  while (currentDate < endDate) {
    results.push(currentDate);
    currentDate = currentDate.plus({ day: 1 });
  }

  return results;
}

/**
 * STYLES
 */

const Calendar = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  tableLayout: 'fixed',
});
const CalendarHeader = styled('thead')({
  height: '64px',
});
const CalendarHeaderCell = styled('th')({});
const CalendarBody = styled('tbody')({
  borderRadius: '8px',
  borderStyle: 'hidden',
  boxShadow: `0 0 0 1px ${hootTokens.palette.neutral[80]}`,
});
const CalendarRow = styled('tr')({});
const CalendarCell = styled('td')({
  border: `solid 1px ${hootTokens.palette.neutral[80]}`,
  height: '175px',
  padding: '0px',
  cursor: 'pointer',
});

const shimmerAnimation = keyframes`
  to {
     background-position-x: 0%
  }
`;

const LoadingAnimation = styled(Stack)({
  borderRadius: '8px',
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `linear-gradient(-45deg, ${hootTokens.palette.neutral['180']} 60%, ${hootTokens.palette.neutral['140']} 70%, ${hootTokens.palette.neutral['180']} 80%)`,
  backgroundSize: '300%',
  backgroundPositionX: '100%',
  animation: `${shimmerAnimation} 1s infinite linear`,
  opacity: 0.5,
});
