import { ScheduledLessonLanguage, ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Card, DialogActions, DialogContent, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SNACKBAR_AUTO_HIDE_DURATION } from '@hoot/constants/constants';
import useGetLessonPlan from '@hoot/hooks/api/lesson-reviews/useGetLessonPlan';
import useGetLessonPlanBookDetails, { LessonPlanBook } from '@hoot/hooks/api/lesson-reviews/useGetLessonPlanBookDetails';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import {
  LegacyLessonReviewWizardStepEnum,
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { BookPickerModalProps } from '../book-picker/BookPickerModal';
import LessonPlanBookPicker from '../book-picker/LessonPlanBookPicker';

interface LessonPlanTableRow {
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
  action: JSX.Element;
}

enum LessonPlanBookFields {
  Title = 'title',
  ResourceProgress = 'resourceProgress',
  ResourceType = 'resourceType',
  InstructionalFocus = 'instructionalFocus',
  InstructionalUnit = 'instructionalUnit',
}

const LegacyLessonReviewLessonPlanStep = () => {
  const { studentProfileId } = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    lessonToReview,
    lessonPlanState,
    actions: { goToNextStep, goToPreviousStep, attachLessonPlanData, getCurrentStepLabel },
    isLessonReviewImprovementsEnabled,
  } = useLessonReviewWizardContext();

  const [, setInitialLessonPlanBooks] = useState<LessonPlanBook[]>(lessonPlanState?.lessonPlanBooks ?? []);
  const [editedLessonPlanBooksMap, setEditedLessonPlanBooksMap] = useState<Map<string, LessonPlanBook>>(
    new Map(lessonPlanState?.lessonPlanBooks.map((b) => [b.id, b])),
  );

  const editedLessonPlanBooks = Array.from(editedLessonPlanBooksMap.values());

  const [bookIdToAdd, setBookIdToAdd] = useState<string>();
  const [bookToRemove, setBookToRemove] = useState<LessonPlanBook | undefined>(undefined);

  const [showAddBookModal, setShowAddBookModal] = useState<boolean>(false);
  const [showUndoDialog, setShowUndoDialog] = useState<boolean>(false);

  const [showError, setShowError] = useState<string>();

  const [sortBy, setSortBy] = useState<string>('title');
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Asc);

  const studentName = lessonToReview?.student.name;
  const isLessonPlanFull = editedLessonPlanBooksMap.size === 5;
  const stepLabel = getCurrentStepLabel(
    isLessonReviewImprovementsEnabled ? LessonReviewWizardStepEnum.LessonPlan : LegacyLessonReviewWizardStepEnum.LessonPlan,
  );

  const {
    isError: lessonPlanError,
    isFetching: lessonPlanFetching,
    data: latestLessonPlanFromApi,
  } = useGetLessonPlan(
    studentProfileId || '',
    {
      lessonSubject: lessonToReview?.subject ?? ScheduledLessonSubject.Reading,
      lessonLanguage: lessonToReview?.language ?? ScheduledLessonLanguage.English,
    },
    {
      retry: false,
      onError: (ex) => {
        console.error(ex);
        setShowError('There was an error while loading the lesson plan.');
      },
      onSuccess: (data) => {
        // If we haven't submitted this step yet, then default this lesson review's lesson plan to be the most
        // up-to-date lesson plan from the API.
        if (!lessonPlanState) {
          const lessonPlanData: LessonPlanBook[] = data.lessonPlanBookDetails.map((book) => ({
            id: book.id,
            title: book.title,
            resourceProgress: book.resourceProgress,
            resourceType: book.resourceType,
            instructionalFocus: book.instructionalFocus,
            instructionalUnit: book.instructionalUnit,
          }));
          setInitialLessonPlanBooks(lessonPlanData);
          setEditedLessonPlanBooksMap(new Map(lessonPlanData.map((b) => [b.id, b])));
        }
      },
    },
  );

  // When we've selected a book from the modal, we have _some_ info, but not everything that we need. We have to do an
  // additional query for that.
  const { isLoading: bookDetailsLoading, isError: bookDetailsError } = useGetLessonPlanBookDetails(
    lessonToReview?.student.id ?? '',
    bookIdToAdd ?? '',
    {
      retry: false,
      enabled: !!bookIdToAdd && !!lessonToReview && !!lessonToReview.student,
      onError: (ex) => {
        console.error(ex);
      },
      onSuccess: (data) => {
        // We have all the data that we need about the book now. Add the book to the lesson plan, and dismiss the add book modal.
        const updatedLessonPlanBooksMap = new Map(editedLessonPlanBooksMap);
        updatedLessonPlanBooksMap.set(data.bookDetails.id, data.bookDetails);
        setEditedLessonPlanBooksMap(updatedLessonPlanBooksMap);

        setBookIdToAdd(undefined);
        setShowAddBookModal(false);
      },
    },
  );

  const hasLessonPlanBeenEdited = () => {
    const latestLessonPlanBookIds = (latestLessonPlanFromApi?.lessonPlanBookDetails ?? []).map((b) => b.id);
    const editedLessonPlanBookIds = new Set(editedLessonPlanBooks.map((b) => b.id));

    const areSetsEqual =
      latestLessonPlanBookIds.length === editedLessonPlanBookIds.size && [...latestLessonPlanBookIds].every((x) => editedLessonPlanBookIds.has(x));

    return !areSetsEqual;
  };

  const headers: HeaderData<LessonPlanTableRow>[] = [
    { name: 'Title', property: LessonPlanBookFields.Title, isSortable: true },
    { name: 'Resource Progress', property: LessonPlanBookFields.ResourceProgress, isSortable: true },
    { name: 'Resource Type', property: LessonPlanBookFields.ResourceType, isSortable: true },
    {
      name: 'Instructional Focus',
      property: LessonPlanBookFields.InstructionalFocus,
      isSortable: false,
      isHidden: !isDesktop,
    },
    {
      name: 'Instructional Unit',
      property: LessonPlanBookFields.InstructionalUnit,
      isSortable: false,
      isHidden: !isDesktop,
    },
    { name: 'Action', property: 'action' },
  ];

  const getViewState = () => {
    if (lessonPlanFetching) {
      return ViewStateEnum.Loading;
    } else if (lessonPlanError || bookDetailsError) {
      return ViewStateEnum.Error;
    } else {
      return ViewStateEnum.Results;
    }
  };

  const removeButton = (book: LessonPlanBook) => {
    const showRemoveBookDialog = (book: LessonPlanBook) => () => {
      setBookToRemove(book);
    };
    return (
      <Button onClick={showRemoveBookDialog(book)} size="small" variant="outlined" color="error.80">
        Remove
      </Button>
    );
  };

  const sortedTableData = useMemo(() => {
    return editedLessonPlanBooks
      .map((book) => ({
        id: book.id,
        title: book.title,
        resourceProgress: book.resourceProgress,
        resourceType: book.resourceType,
        instructionalFocus: book.instructionalFocus,
        instructionalUnit: book.instructionalUnit,
        action: removeButton(book),
      }))
      .sort((a, b) => {
        switch (sortBy) {
          case LessonPlanBookFields.Title:
            return orderBy === OrderBy.Asc ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
          case LessonPlanBookFields.ResourceProgress:
            return orderBy === OrderBy.Asc
              ? a.resourceProgress.localeCompare(b.resourceProgress)
              : b.resourceProgress.localeCompare(a.resourceProgress);
          case LessonPlanBookFields.ResourceType:
            return orderBy === OrderBy.Asc ? a.resourceType.localeCompare(b.resourceType) : b.resourceType.localeCompare(a.resourceType);
          case LessonPlanBookFields.InstructionalFocus:
            return orderBy === OrderBy.Asc
              ? a.instructionalFocus.localeCompare(b.instructionalFocus)
              : b.instructionalFocus.localeCompare(a.instructionalFocus);
          case LessonPlanBookFields.InstructionalUnit:
            return orderBy === OrderBy.Asc
              ? a.instructionalUnit.localeCompare(b.instructionalUnit)
              : b.instructionalUnit.localeCompare(a.instructionalUnit);
          default:
            return orderBy === OrderBy.Asc ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        }
      });
  }, [editedLessonPlanBooks, orderBy, sortBy]);

  const handleRemoveBook = () => {
    if (bookToRemove) {
      const updatedLessonPlanBooksMap = new Map(editedLessonPlanBooksMap);
      updatedLessonPlanBooksMap.delete(bookToRemove.id);
      setEditedLessonPlanBooksMap(updatedLessonPlanBooksMap);
    }
    setBookToRemove(undefined);
  };

  const handleUndoChanges = () => {
    setEditedLessonPlanBooksMap(new Map((latestLessonPlanFromApi?.lessonPlanBookDetails ?? []).map((b) => [b.id, b])));
    setShowUndoDialog(false);
  };

  const loadBookDetailsAndAddToLessonPlan: BookPickerModalProps['onApply'] = (book) => {
    // If we haven't already added this book, then fetch the book details and add it to the lesson plan.
    if (!editedLessonPlanBooksMap.has(book.id)) {
      setBookIdToAdd(book.id);
    }
  };

  const onClickNext = () => {
    attachLessonPlanData(Array.from(editedLessonPlanBooks.values()));
    goToNextStep();
  };

  return (
    <>
      <Snackbar
        variant="error"
        message={`There was an error while loading the lesson plan.`}
        open={!!showError}
        onClose={() => setShowError(undefined)}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
      {showAddBookModal ? (
        <LessonPlanBookPicker
          show={showAddBookModal}
          onApply={loadBookDetailsAndAddToLessonPlan}
          onDismiss={() => setShowAddBookModal(false)}
          existingBookIds={editedLessonPlanBooks.map((book) => book.id)}
          isLoading={bookDetailsLoading}
          studentProfileId={lessonToReview?.student.id}
        />
      ) : null}
      <BasicAlertDialog
        show={!!bookToRemove}
        onDismiss={() => setBookToRemove(undefined)}
        title={'Remove Book?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            Are you sure you want to remove <strong>{bookToRemove?.title}</strong>?
          </HootTypography>
        }
        primaryAction={{
          label: 'Remove',
          onClick: handleRemoveBook,
          props: {
            variant: 'contained',
            color: 'error',
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setBookToRemove(undefined),
        }}
      />
      <BasicAlertDialog
        show={showUndoDialog}
        onDismiss={() => setShowUndoDialog(false)}
        title={'Undo All Changes?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            All progress will be lost
          </HootTypography>
        }
        primaryAction={{
          label: 'Undo',
          onClick: handleUndoChanges,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setShowUndoDialog(false),
        }}
      />
      <DialogContent>
        <Stack gap={'16px'} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            {`Step ${stepLabel} - Lesson Plan`}
          </HootTypography>
          <HootTypography isPII={true} variant="bodymedium">
            Please ensure <strong>{studentName}</strong>'s lesson plan is up to date by adding or removing books.
          </HootTypography>
          <ViewState state={getViewState()} loadingContent={<SkeletonTableItems />}>
            {!!editedLessonPlanBooks.length ? (
              <Card>
                <Stack gap={'16px'}>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <HootTypography isPII={true} variant="titlemedium">
                      {' '}
                      {studentName}'s Lesson Plan{' '}
                    </HootTypography>
                    <Stack gap={'8px'} direction={'row'}>
                      <Button size="small" variant="outlined" onClick={() => setShowUndoDialog(true)} disabled={!hasLessonPlanBeenEdited()}>
                        Undo Changes
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        disabled={isLessonPlanFull}
                        onClick={() => setShowAddBookModal(true)}
                        color="success.60"
                      >
                        {isLessonPlanFull ? 'Full' : 'Add Book'}
                      </Button>
                    </Stack>
                  </Stack>
                  <TableV2
                    isPaginated={false}
                    data={sortedTableData}
                    headers={headers}
                    isSortable
                    onSortBy={(val: any) => {
                      setSortBy(val);
                      setOrderBy((orderBy) => (val === sortBy ? (orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc) : orderBy));
                    }}
                    sortOrder={orderBy}
                    sortBy={sortBy as any}
                    emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
                  />
                  <HootTypography isPII={false} variant="bodysmall" sx={{ textAlign: 'right' }}>
                    {editedLessonPlanBooks.length} Book{editedLessonPlanBooks.length > 1 ? 's' : ''} in Plan
                  </HootTypography>
                </Stack>
              </Card>
            ) : (
              <Card sx={{ maxWidth: '372px' }}>
                <HootTypography isPII={true} variant="titlemedium">
                  {' '}
                  {studentName}'s Lesson Plan{' '}
                </HootTypography>
                <Stack>
                  <ViewStateIllustration
                    illustration={IllustrationEnum.EmptyInbox}
                    title={`${studentName}'s Plan is empty`}
                    subtitle="Add some books to continue"
                    titleSx={{ ...hootTokens.text.titlesmall }}
                    subtitleSx={{ ...hootTokens.text.bodysmall }}
                  />
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button size="small" variant="contained" onClick={() => setShowAddBookModal(true)}>
                      <Icon name="add" sx={{ color: 'white', marginRight: '16px' }} /> Add Books{' '}
                    </Button>
                  </Box>
                </Stack>
              </Card>
            )}
          </ViewState>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={onClickNext} disabled={editedLessonPlanBooks.length < 1}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

const SkeletonTableItems = () => (
  <Stack direction="column" gap={3} sx={{ width: '100%' }}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '60px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '260px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '75px' }} />
    </Box>
  </Stack>
);

export default LegacyLessonReviewLessonPlanStep;
